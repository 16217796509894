import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getGenderFilterConfig } from '../filters/gender';

const livieandlucaSizes = [
  '1Y',
  '2Y',
  '3Y',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
];

const categoryPercentages = {
  boots: 0.07,
  sneakers: 0.07,
  shoes: 0.07,
  maryJanes: 0.07,
  flats: 0.07,
  firstwalkers: 0.07,
  sandals: 0.07,
};

const tagPercentages = {
  occasion_holiday: 0.079,
  boots: 0.074,
  sneakers: 0.059,
  navy: 0.049,
  'first walker': 0.044,
  textile: 0.039,
  uniform: 0.039,
  ecobreathe: 0.039,
  vegan: 0.039,
  flats: 0.036,
  'eco-rejoy': 0.034,
  leather: 0.034,
  'Slip-On': 0.029,
  'st patricks day': 0.029,
  'Easter: Casual': 0.029,
  'Easter: Dressy': 0.029,
  'Easy On Easy Off': 0.028,
  gse: 0.027,
  blue: 0.026,
  cocreation: 0.026,
  sandals: 0.021,
  synthetic: 0.025,
  MultifitInsoleCompatible: 0.025,
  'co-creation spotlight': 0.024,
  'sole_first-walkers': 0.024,
  antimicrobial: 0.024,
  adaptive: 0.024,
  metallic: 0.023,
  gold: 0.023,
  'mary-janes': 0.023,
  sole_dancer2: 0.021,
  sole_trac2: 0.021,
  sole_jumper: 0.021,
  sole_honeycomb2: 0.021,
  sole_cycle: 0.021,
  casual: 0.021,
  pink: 0.021,
  suede: 0.02,
  'Wholesale Starter Styles': 0.019,
  'under-25': 0.019,
  zipper: 0.019,
  brown: 0.019,
  'Recycled Materials': 0.019,
  'Wholesale-All Year Core Styles': 0.019,
  StickerCompatible: 0.019,
  rejoy: 0.019,
  'washable-cold': 0.019,
  shoes: 0.019,
  'B2G1 Eligible': 0.016,
  'Full Price': 0.016,
  '$45-$70': 0.015,
  'under-40': 0.014,
  unisex: 0.014,
  'little kids': 0.014,
  boys: 0.014,
  girls: 0.014,
  '25-to-45': 0.014,
  '45-to-70': 0.014,
  ARCHIVED: 0.012,
  'size-8': 0.011,
  'size-6': 0.011,
  'size-1y': 0.011,
  '50-75': 0.011,
  'size-11': 0.011,
  'size-13': 0.011,
  'size-9': 0.011,
  'size-7': 0.011,
  'size-2y': 0.011,
  'size-12': 0.011,
  'Target+': 0.011,
  'size-10': 0.011,
  'size-3y': 0.011,
  print: 0.004,
  loop: 0.004,
  't-strap': -0.001,
  'FINAL SALE': -0.001,
  'hook & loop': -0.001,
  clearance: -0.006,
};

const livieandlucaConditions = [
  {
    key: Conditions.NewWithTags,
    label: 'New',
    shortLabel: 'New',
    description: 'Your shoes have not been worn and also have no quirks or issues whatsoever.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent',
    shortLabel: 'Excellent',
    description:
      'Your shoes have only been lightly used and have been extremely well maintained. There may be some small signs of wear, but nothing major.',
  },
  {
    key: Conditions.Good,
    label: 'Good',
    shortLabel: 'Good',
    description:
      'You’ve worn the shoes and they’re well maintained. There may be some quirks like a small stain or a scuff mark, but they’re overall in good shape.',
  },
];

export const livieandlucaShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getGenderFilterConfig(),
    getConditionsFilterConfig({ options: livieandlucaConditions }),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: livieandlucaSizes }),
  ],
  resaleValueConfig: {
    categoryPercentages,
    tagPercentages,
  },
};
