import React from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconMegaphoneProps {
  className?: string;
  color?: string;
}

const IconMegaphone: React.FC<IconMegaphoneProps> = (props) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0267 20.7476C23.5986 20.3264 23.9948 16.7084 22.9118 12.6666C21.8288 8.62472 19.6767 5.68959 18.1048 6.11076C16.533 6.53193 16.1367 10.1499 17.2197 14.1917C18.3028 18.2336 20.4549 21.1687 22.0267 20.7476ZM21.3731 18.3081C22.0467 18.1276 22.0075 15.7969 21.2855 13.1023C20.5635 10.4077 19.4321 8.36969 18.7585 8.5502C18.0848 8.7307 18.124 11.0614 18.846 13.756C19.568 16.4505 20.6994 18.4886 21.3731 18.3081Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7567 20.9972C19.2469 20.8257 17.3877 18.072 16.4064 14.4097C15.4251 10.7473 15.6583 7.43285 16.8802 6.52952C16.6674 6.59054 16.4612 6.69601 16.2742 6.8499L10.7436 11.3999C10.5476 11.5611 10.4213 11.7919 10.3913 12.044C10.0767 14.6816 10.7888 17.3391 12.38 19.466C12.5321 19.6693 12.7568 19.806 13.0072 19.8476L20.0718 21.0227C20.3107 21.0625 20.5419 21.0508 20.7567 20.9972Z"
        fill={fillColor}
      />
      <rect
        width="4.2092"
        height="1.68368"
        rx="0.84184"
        transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 23.7172 9.16687)"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.8939 20.7126C4.99571 20.9532 3.87741 19.6921 3.39607 17.8957C2.91474 16.0994 3.25266 14.448 4.15085 14.2074C4.15432 14.2064 4.1578 14.2055 4.16128 14.2046L9.57402 12.7543C9.4131 15.0562 10.0268 17.3465 11.3171 19.2595L5.89391 20.7127L5.8939 20.7126Z"
        fill={fillColor}
      />
      <path
        d="M10.1775 20.4363L6.92493 21.3079L9.7499 26.0196C9.94317 26.3419 10.3267 26.4971 10.6898 26.3998L10.8896 26.3463C11.3387 26.226 11.6052 25.7643 11.4849 25.3153L10.1775 20.4363Z"
        fill={fillColor}
      />
      <rect
        x="25.4097"
        y="15.4835"
        width="4.2092"
        height="1.68368"
        rx="0.84184"
        transform="rotate(15 25.4097 15.4835)"
        fill={fillColor}
      />
      <rect
        x="24.3202"
        y="11.4177"
        width="4.2092"
        height="1.68368"
        rx="0.84184"
        transform="rotate(-15 24.3202 11.4177)"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconMegaphone;
