import { ThunkDispatch } from 'redux-thunk';
import { RequestStatus } from '../../types/requestStatus';
import * as log from '../../util/log';
import { storableError } from '../../util/errors';
import {
  fetchBrandRevenue as fetchBrandRevenueApi,
  fetchRetoolEmbedUrl as fetchRetoolEmbedUrlApi,
  queueGenerateReportsForShopJob,
} from '../../util/api';

// ================ Action types ================ //

const FETCH_RETOOL_EMBED_URL_REQUEST = 'app/AdminPage/FETCH_RETOOL_EMBED_URL_REQUEST';
const FETCH_RETOOL_EMBED_URL_SUCCESS = 'app/AdminPage/FETCH_RETOOL_EMBED_URL_SUCCESS';
const FETCH_RETOOL_EMBED_URL_ERROR = 'app/AdminPage/FETCH_RETOOL_EMBED_URL_ERROR';

const CREATE_REPORTS_REQUEST = 'app/AdminPage/CREATE_REPORTS_REQUEST';
const CREATE_REPORTS_SUCCESS = 'app/AdminPage/CREATE_REPORTS_SUCCESS';
const CREATE_REPORTS_ERROR = 'app/AdminPage/CREATE_REPORTS_ERROR';

const FETCH_BRAND_REVENUE_REQUEST = 'app/AdminPage/FETCH_BRAND_REVENUE_REQUEST';
const FETCH_BRAND_REVENUE_SUCCESS = 'app/AdminPage/FETCH_BRAND_REVENUE_SUCCESS';
const FETCH_BRAND_REVENUE_ERROR = 'app/AdminPage/FETCH_BRAND_REVENUE_ERROR';

interface FetchRetoolEmbedUrlRequest {
  type: typeof FETCH_RETOOL_EMBED_URL_REQUEST;
}

interface FetchRetoolEmbedUrlSuccess {
  type: typeof FETCH_RETOOL_EMBED_URL_SUCCESS;
  embedUrl: string;
}

interface FetchRetoolEmbedUrlError {
  type: typeof FETCH_RETOOL_EMBED_URL_ERROR;
  error: any;
}

interface CreateReportsRequest {
  type: typeof CREATE_REPORTS_REQUEST;
}

interface CreateReportsSuccess {
  type: typeof CREATE_REPORTS_SUCCESS;
}

interface CreateReportsError {
  type: typeof CREATE_REPORTS_ERROR;
  error: any;
}

interface FetchBrandRevenueRequest {
  type: typeof FETCH_BRAND_REVENUE_REQUEST;
}

interface FetchBrandRevenueSuccess {
  type: typeof FETCH_BRAND_REVENUE_SUCCESS;
  brandRevenue: any;
}

interface FetchBrandRevenueError {
  type: typeof FETCH_BRAND_REVENUE_ERROR;
  error: any;
}

type AdminPageActionType =
  | CreateReportsRequest
  | CreateReportsSuccess
  | CreateReportsError
  | FetchRetoolEmbedUrlRequest
  | FetchRetoolEmbedUrlSuccess
  | FetchRetoolEmbedUrlError
  | FetchBrandRevenueRequest
  | FetchBrandRevenueSuccess
  | FetchBrandRevenueError;

export interface AdminPageState {
  createReportsStatus: RequestStatus;
  createReportsError: any | null;
  retoolEmbedUrl: string;
  fetchRetoolEmbedUrlStatus: RequestStatus;
  fetchRetoolEmbedUrlError: any | null;
  brandRevenue: any | null;
  fetchBrandRevenueStatus: RequestStatus;
  fetchBrandRevenueError: any | null;
}

const initialState: AdminPageState = {
  createReportsStatus: RequestStatus.Ready,
  createReportsError: null,
  retoolEmbedUrl: '',
  fetchRetoolEmbedUrlStatus: RequestStatus.Ready,
  fetchRetoolEmbedUrlError: null,
  brandRevenue: null,
  fetchBrandRevenueStatus: RequestStatus.Ready,
  fetchBrandRevenueError: null,
};

export default function AdminPageReducer(
  state: AdminPageState = initialState,
  action: AdminPageActionType
): AdminPageState {
  switch (action.type) {
    case FETCH_RETOOL_EMBED_URL_REQUEST: {
      return {
        ...state,
        fetchRetoolEmbedUrlStatus: RequestStatus.Pending,
        fetchRetoolEmbedUrlError: null,
      };
    }
    case FETCH_RETOOL_EMBED_URL_SUCCESS: {
      return {
        ...state,
        fetchRetoolEmbedUrlStatus: RequestStatus.Success,
        retoolEmbedUrl: action.embedUrl,
      };
    }
    case FETCH_RETOOL_EMBED_URL_ERROR: {
      return {
        ...state,
        fetchRetoolEmbedUrlStatus: RequestStatus.Error,
        fetchRetoolEmbedUrlError: action.error,
      };
    }
    case CREATE_REPORTS_REQUEST: {
      return {
        ...state,
        createReportsStatus: RequestStatus.Pending,
        createReportsError: null,
      };
    }
    case CREATE_REPORTS_SUCCESS: {
      return {
        ...state,
        createReportsStatus: RequestStatus.Success,
      };
    }
    case CREATE_REPORTS_ERROR: {
      return {
        ...state,
        createReportsStatus: RequestStatus.Error,
        createReportsError: action.error,
      };
    }
    case FETCH_BRAND_REVENUE_REQUEST: {
      return {
        ...state,
        fetchBrandRevenueStatus: RequestStatus.Pending,
        fetchBrandRevenueError: null,
      };
    }
    case FETCH_BRAND_REVENUE_SUCCESS: {
      return {
        ...state,
        fetchBrandRevenueStatus: RequestStatus.Success,
        brandRevenue: action.brandRevenue,
      };
    }
    case FETCH_BRAND_REVENUE_ERROR: {
      return {
        ...state,
        fetchBrandRevenueStatus: RequestStatus.Error,
        fetchBrandRevenueError: action.error,
      };
    }
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const createReportsRequest = () => ({ type: CREATE_REPORTS_REQUEST });

export const createReportsSuccess = () => ({ type: CREATE_REPORTS_SUCCESS });

export const createReportsError = (error: any) => ({
  type: CREATE_REPORTS_ERROR,
  error,
});

export const fetchRetoolEmbedUrlRequest = () => ({ type: FETCH_RETOOL_EMBED_URL_REQUEST });

export const fetchRetoolEmbedUrlSuccess = (embedUrl: string) => ({
  type: FETCH_RETOOL_EMBED_URL_SUCCESS,
  embedUrl,
});

export const fetchRetoolEmbedUrlError = (error: any) => ({
  type: FETCH_RETOOL_EMBED_URL_ERROR,
  error,
});

export const fetchBrandRevenueRequest = () => ({ type: FETCH_BRAND_REVENUE_REQUEST });

export const fetchBrandRevenueSuccess = (brandRevenue: any) => ({
  type: FETCH_BRAND_REVENUE_SUCCESS,
  brandRevenue,
});

export const fetchBrandRevenueError = (error: any) => ({
  type: FETCH_BRAND_REVENUE_ERROR,
  error,
});

// =========== Thunks ========== //

export const createReports =
  (startDate: Date, endDate: Date) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => any) => {
    const rootState = getState();
    const { treetId } = rootState.initial;

    dispatch(createReportsRequest());

    try {
      const response = await queueGenerateReportsForShopJob({
        treetId,
        startDate,
        endDate,
      });
      dispatch(createReportsSuccess());
      return response;
    } catch (e) {
      log.error(e, 'create-reports-error', { treetId, startDate, endDate });
      dispatch(createReportsError(storableError(e)));
      throw e;
    }
  };

export const fetchRetoolEmbedUrl =
  (shopName: string, appId: string, currencyCode?: string) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => any) => {
    const rootState = getState();
    const { treetId } = rootState.initial;
    dispatch(fetchRetoolEmbedUrlRequest());
    try {
      const response = await fetchRetoolEmbedUrlApi({ treetId, shopName, appId, currencyCode });
      dispatch(fetchRetoolEmbedUrlSuccess(response.embedUrl));
    } catch (e) {
      log.error(e, 'fetch-retool-embed-url-error', { treetId });
      dispatch(fetchRetoolEmbedUrlError(storableError(e)));
      throw e;
    }
  };

export const fetchBrandRevenue =
  () => async (dispatch: ThunkDispatch<any, any, any>, getState: () => any) => {
    const rootState = getState();
    const { treetId } = rootState.initial;
    dispatch(fetchBrandRevenueRequest());
    try {
      const response = await fetchBrandRevenueApi({ treetId });
      dispatch(fetchBrandRevenueSuccess(response.data.totalRevenue));
    } catch (e) {
      log.error(e, 'fetch-brand-revenue-error', { treetId });
      dispatch(fetchBrandRevenueError(storableError(e)));
      throw e;
    }
  };
