import React, { FC } from 'react';
import classNames from 'classnames';
import { useIconColor } from '../../../hooks/useIconColor';
import css from './IconHome.module.css';

interface IconHomeProps {
  className?: string;
  color?: string;
}

const IconHome: FC<IconHomeProps> = (props: IconHomeProps) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={classNames(css.root, className)}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6364 18.2727H11.6364V19.2727V28.1818H5V13.2921L16 4.29206L27 13.2921V28.1818H20.3637V19.2727V18.2727H19.3637H12.6364Z"
        stroke={fillColor}
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconHome;
