/* Initialize Weglot with the user's preferred language.
Language code must be lowercase.
*/
// eslint-disable-next-line import/prefer-default-export
export const setWeGlotLanguageToUserPreferredLanguage = (currentUser) => {
  if (typeof window !== 'undefined' && window.Weglot) {
    const preferredLanguage = currentUser.attributes.profile.privateData?.preferredLocale
      ? currentUser.attributes.profile.privateData?.preferredLocale.split('_')[0].toLowerCase()
      : 'en';
    window.Weglot.switchTo(preferredLanguage);
  }
};
