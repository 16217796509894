import React, { useContext } from 'react';
import bowser from 'bowser';
import { Box } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Carousel from 'react-multi-carousel';
import { arrayOf, string } from 'prop-types';
import { ListingCard, TypographyWrapper } from '..';
import AppContext from '../../context/AppContext';
import { propTypes } from '../../util/types';
import { useShopConfig } from '../../hooks/shopConfig';
import css from './ListingItemsCarousel.module.css';

export const MIN_CAROUSEL_LISTINGS = 4;
const MAX_CAROUSEL_LISTINGS = 15;

// based on https://github.com/YIZHUANG/react-multi-carousel/blob/master/examples/ssr/pages/index.js
const ListingItemsCarousel = ({
  listings,
  referrerLocation,
  title,
  trackClickListingCardHeapEvent,
}) => {
  const { css: shopCss } = useShopConfig();
  const { userAgent } = useContext(AppContext);
  // logic based on https://github.com/YIZHUANG/react-multi-carousel/blob/master/examples/ssr/pages/index.js
  let deviceType;
  if (userAgent) {
    const browser = bowser.getParser(userAgent);
    deviceType = browser.getPlatform().type;
  } else {
    deviceType = 'desktop';
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 550 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  if (!listings || listings.length < MIN_CAROUSEL_LISTINGS) {
    return null;
  }

  const listingCardStyle = shopCss?.listingCardStyle || {};

  return (
    <div className={css.root}>
      {title && (
        <Box pb={6}>
          <TypographyWrapper variant="h1">{title}</TypographyWrapper>
        </Box>
      )}
      <div className={css.listingsContainer}>
        <Carousel
          responsive={responsive}
          ssr
          containerClass="container-with-dots"
          itemClass="image-item"
          deviceType={deviceType}
          // removeArrowOnDeviceType={['mobile']}
          customLeftArrow={<ChevronLeftIcon className={css.leftIcon} />}
          customRightArrow={<ChevronRightIcon className={css.rightIcon} />}
        >
          {listings.slice(0, MAX_CAROUSEL_LISTINGS).map((l) => (
            <ListingCard
              key={l.id.uuid}
              listing={l}
              referrerLocation={referrerLocation}
              trackClickListingCardHeapEvent={trackClickListingCardHeapEvent}
              style={{ padding: '0 4px', ...listingCardStyle }}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

ListingItemsCarousel.defaultProps = {
  title: '',
};

ListingItemsCarousel.propTypes = {
  listings: arrayOf(propTypes.listing).isRequired,
  referrerLocation: string.isRequired,
  title: string,
};

export default ListingItemsCarousel;
