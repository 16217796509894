import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';

const ergopouchSizes = [
  '0-3M',
  '2-6M',
  '3-6M',
  '2-12M',
  '3-12M',
  '6-12M',
  '8-24M',
  '12-24M',
  '12-36M',
  '1Y',
  '2Y',
  '2-3Y',
  '2-4Y',
  '3Y',
  '3-4Y',
  '4Y',
  '4-5Y',
  '4-6Y',
  '5Y',
  '6Y',
  '6-8Y',
  '8-10Y',
  '10-12Y',
  'S',
  'M',
  'L',
  'XL',
  'Bassinet',
  'Bedside Sleeper',
  'Cot',
  'One Size',
  'Toddler',
];

export const ergopouchShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: ergopouchSizes }),
  ],
};
