/* eslint-disable import/no-cycle */
import React from 'react';
import loadable from '@loadable/component';
import getPageDataLoadingAPI from '../containers/pageDataLoadingAPI';
// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import NamedRedirect from '../components/NamedRedirect/NamedRedirect';
import { ListingPageParamType } from '../util/urlHelpers';
import {
  DRAFT_ID,
  DRAFT_SLUG,
  ListingPageParamTab,
} from '../containers/EditListingPage/EditListingPage.utils';
import { doesRouteHaveViews } from '../util/routes';
import { getSubdomain } from '../util/envHelpers';
import { Feature, isFeatureEnabled } from '../util/featureFlags';
import { ROUTES } from './routeConstants';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AboutPage = loadable(() =>
  import(/* webpackChunkName: "AboutPage" */ '../containers/AboutPage/AboutPage')
);
const AboutTreetPage = loadable(() =>
  import(/* webpackChunkName: "AboutTreetPage" */ '../containers/AboutTreetPage/AboutTreetPage')
);
const AdminPage = loadable(() =>
  import(/* webpackChunkName: "AdminPage" */ '../containers/AdminPage/AdminPage')
);
const AuthenticationRedirectPage = loadable(() =>
  import(
    /* webpackChunkName: "AuthenticationRedirectPage" */ '../containers/AuthenticationRedirectPage/AuthenticationRedirectPage'
  )
);
const AuthenticationPage = loadable(() =>
  import(
    /* webpackChunkName: "AuthenticationPage" */ '../containers/AuthenticationPage/AuthenticationPage'
  )
);
const CheckoutPage = loadable(() =>
  import(/* webpackChunkName: "CheckoutPage" */ '../containers/CheckoutPage/CheckoutPage')
);
const ContactDetailsPage = loadable(() =>
  import(
    /* webpackChunkName: "ContactDetailsPage" */ '../containers/ContactDetailsPage/ContactDetailsPage'
  )
);
const CookiePolicyPage = loadable(() =>
  import(
    /* webpackChunkName: "CookiePolicyPage" */ '../containers/CookiePolicyPage/CookiePolicyPage'
  )
);
const SubprocessorsPage = loadable(() =>
  import(
    /* webpackChunkName: "SubprocessorsPage" */ '../containers/SubprocessorsPage/SubprocessorsPage'
  )
);
const EditListingPage = loadable(() =>
  import(/* webpackChunkName: "EditListingPage" */ '../containers/EditListingPage/EditListingPage')
);
const EmailVerificationPage = loadable(() =>
  import(
    /* webpackChunkName: "EmailVerificationPage" */ '../containers/EmailVerificationPage/EmailVerificationPage'
  )
);
const FAQPage = loadable(() =>
  import(/* webpackChunkName: "FAQPage" */ '../containers/FAQPage/FAQPage')
);
const FavoritesPage = loadable(() =>
  import(/* webpackChunkName: "FavoritesPage" */ '../containers/FavoritesPage/FavoritesPage')
);
const NotificationSettingsPage = loadable(() =>
  import(
    /* webpackChunkName: "NotificationSettingsPage" */ '../containers/NotificationSettingsPage/NotificationSettingsPage'
  )
);
const GenerateShippingLabelPage = loadable(() =>
  import(
    /* webpackChunkName: "GenerateShippingLabelPage" */ '../containers/GenerateShippingLabelPage/GenerateShippingLabelPage'
  )
);
const HomePage = loadable(() =>
  import(/* webpackChunkName: "HomePage" */ '../containers/HomePage/HomePage')
);
const ListingPage = loadable(() =>
  import(
    /* webpackChunkName: "ListingPage" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPage'
  )
);
const ManageListingsPage = loadable(() =>
  import(
    /* webpackChunkName: "ManageListingsPage" */ '../containers/ManageListingsPage/ManageListingsPage'
  )
);
const ManagePurchasesPage = loadable(() =>
  import(
    /* webpackChunkName: "ManagePurchasesPage" */ '../containers/ManagePurchasesPage/ManagePurchasesPage'
  )
);
const ManageSalesPage = loadable(() =>
  import(/* webpackChunkName: "ManageSalesPage" */ '../containers/ManageSalesPage/ManageSalesPage')
);

const ManageClosetPage = loadable(() =>
  import(
    /* webpackChunkName: "ManageClosetPage" */ '../containers/ManageClosetPage/ManageClosetPage'
  )
);

const ManageTradeInsPage = loadable(() =>
  import(
    /* webpackChunkName: "ManageTradeInsPage" */ '../containers/ManageTradeInsPage/ManageTradeInsPage'
  )
);
const PasswordChangePage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordChangePage" */ '../containers/PasswordChangePage/PasswordChangePage'
  )
);
const PasswordRecoveryPage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordRecoveryPage" */ '../containers/PasswordRecoveryPage/PasswordRecoveryPage'
  )
);
const PasswordResetPage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordResetPage" */ '../containers/PasswordResetPage/PasswordResetPage'
  )
);
const PaymentMethodsPage = loadable(() =>
  import(
    /* webpackChunkName: "PaymentMethodsPage" */ '../containers/PaymentMethodsPage/PaymentMethodsPage'
  )
);
const PrivacyPolicyPage = loadable(() =>
  import(
    /* webpackChunkName: "PrivacyPolicyPage" */ '../containers/PrivacyPolicyPage/PrivacyPolicyPage'
  )
);
const ProfilePage = loadable(() =>
  import(/* webpackChunkName: "ProfilePage" */ '../containers/ProfilePage/ProfilePage')
);
const ProfileSettingsPage = loadable(() =>
  import(
    /* webpackChunkName: "ProfileSettingsPage" */ '../containers/ProfileSettingsPage/ProfileSettingsPage'
  )
);
const OrderSuccessPage = loadable(() =>
  import(
    /* webpackChunkName: "OrderSuccessPage" */ '../containers/OrderSuccessPage/OrderSuccessPage'
  )
);

const StripePayoutPage = loadable(() =>
  import(
    /* webpackChunkName: "StripePayoutPage" */ '../containers/StripePayoutPage/StripePayoutPage'
  )
);
const TermsOfServicePage = loadable(() =>
  import(
    /* webpackChunkName: "TermsOfServicePage" */ '../containers/TermsOfServicePage/TermsOfServicePage'
  )
);
const TreetProtectionPage = loadable(() =>
  import(
    /* webpackChunkName: "TreetProtectionPage" */ '../containers/TreetProtectionPage/TreetProtectionPage'
  )
);
const TreetShopLandingPage = loadable(() =>
  import(
    /* webpackChunkName: "TreetShopLandingPage" */ '../containers/TreetShopLandingPage/TreetShopLandingPage'
  )
);

const NotFoundPage = loadable(() =>
  import(/* webpackChunkName: "NotFoundPage" */ '../containers/NotFoundPage/NotFoundPage')
);

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;
// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = (treetId) => {
  const isTreetShop = treetId === 'treet';
  const subdomain = getSubdomain();
  const hasViews = doesRouteHaveViews(subdomain);
  const isGuestListingEnabled = isFeatureEnabled(Feature.GuestListing, subdomain);

  if (!hasViews) {
    return [
      {
        path: ROUTES.NotFoundPage.path,
        name: ROUTES.NotFoundPage.name,
        component: NotFoundPage,
      },
    ];
  }
  // TODO (sonia-y | TREET-1511): Clean up unused routes after TreetV2 is launched
  const treetShopRoutes = [
    {
      path: ROUTES.TreetShopLandingPage.path,
      name: ROUTES.TreetShopLandingPage.name,
      component: TreetShopLandingPage,
      loadData: pageDataLoadingAPI.TreetShopLandingPage.loadData,
    },
    {
      path: ROUTES.TreetShopLandingPagePath.path,
      name: ROUTES.TreetShopLandingPagePath.name,
      component: () => <NamedRedirect name={ROUTES.TreetShopLandingPagePath.name} />,
      exact: false,
    },
  ];

  const treetAppRoutes = [
    {
      path: ROUTES.SearchPage.path,
      name: ROUTES.SearchPage.name,
      component: HomePage,
    },
    {
      path: ROUTES.LandingPage.path,
      name: ROUTES.LandingPage.name,
      component: HomePage,
    },
    {
      path: ROUTES.DeprecatedSearchPage.path,
      name: ROUTES.DeprecatedSearchPage.name,
      component: HomePage,
    },
    {
      path: ROUTES.AboutTreetPage.path,
      name: ROUTES.AboutTreetPage.name,
      component: AboutTreetPage,
    },
    {
      path: ROUTES.TreetProtectionPage.path,
      name: ROUTES.TreetProtectionPage.name,
      component: TreetProtectionPage,
    },
    {
      path: ROUTES.AboutBasePage.path,
      name: ROUTES.AboutBasePage.name,
      component: () => <NamedRedirect name={ROUTES.AboutPage.name} params={{ tab: 'info' }} />,
    },
    {
      path: ROUTES.AboutPage.path,
      name: ROUTES.AboutPage.name,
      component: AboutPage,
    },
    {
      path: ROUTES.AdminBasePage.path,
      name: ROUTES.AdminBasePage.name,
      component: () => <NamedRedirect name={ROUTES.AdminPage.name} params={{ tab: 'dashboard' }} />,
    },
    {
      path: ROUTES.AdminPage.path,
      name: ROUTES.AdminPage.name,
      component: AdminPage,
    },
    {
      path: ROUTES.AdminPageVariant.path,
      name: ROUTES.AdminPageVariant.name,
      component: AdminPage,
    },
    {
      path: ROUTES.AdminPagePane.path,
      name: ROUTES.AdminPagePane.name,
      component: AdminPage,
    },
    {
      path: ROUTES.ListingBasePage.path,
      name: ROUTES.ListingBasePage.name,
      component: RedirectToLandingPage,
    },
    {
      path: ROUTES.ListingPage.path,
      name: ROUTES.ListingPage.name,
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: ROUTES.CheckoutPage.path,
      name: ROUTES.CheckoutPage.name,
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: ROUTES.ListingPageVariant.path,
      name: ROUTES.ListingPageVariant.name,
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: ROUTES.NewListingPage.path,
      name: ROUTES.NewListingPage.name,
      auth: !isGuestListingEnabled,
      component: () => (
        <NamedRedirect
          name={ROUTES.EditListingPage.name}
          params={{
            slug: DRAFT_SLUG,
            id: DRAFT_ID,
            type: ListingPageParamType.New,
            tab: ListingPageParamTab.Search,
          }}
        />
      ),
    },
    {
      path: ROUTES.EditListingPage.path,
      name: ROUTES.EditListingPage.name,
      auth: !isGuestListingEnabled,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: ROUTES.ListingPageCanonical.path,
      name: ROUTES.ListingPageCanonical.name,
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: ROUTES.ProfileBasePage.path,
      name: ROUTES.ProfileBasePage.name,
      component: RedirectToLandingPage,
    },
    {
      path: ROUTES.ProfilePage.path,
      name: ROUTES.ProfilePage.name,
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: ROUTES.ProfileSettingsPage.path,
      name: ROUTES.ProfileSettingsPage.name,
      auth: true,
      authPage: ROUTES.LoginPage.name,
      component: ProfileSettingsPage,
    },
    {
      path: ROUTES.NotificationSettingsPage.path,
      name: ROUTES.NotificationSettingsPage.name,
      auth: true,
      authPage: ROUTES.LoginPage.name,
      component: NotificationSettingsPage,
    },
    // Note: authenticating with IdP (e.g. Google) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: ROUTES.LoginPage.path,
      name: ROUTES.LoginPage.name,
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: ROUTES.SignupPage.path,
      name: ROUTES.SignupPage.name,
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
    },
    {
      path: ROUTES.PasswordRecoveryPage.path,
      name: ROUTES.PasswordRecoveryPage.name,
      component: PasswordRecoveryPage,
    },
    {
      path: ROUTES.OrderSuccessPage.path,
      name: ROUTES.OrderSuccessPage.name,
      auth: true,
      authPage: ROUTES.LoginPage.name,
      component: OrderSuccessPage,
      setInitialValues: pageDataLoadingAPI.OrderSuccessPage.setInitialValues,
      loadData: pageDataLoadingAPI.OrderSuccessPage.loadData,
    },
    {
      path: ROUTES.FavoritesPage.path,
      name: ROUTES.FavoritesPage.name,
      auth: true,
      authPage: ROUTES.LoginPage.name,
      component: FavoritesPage,
      loadData: pageDataLoadingAPI.FavoritesPage.loadData,
    },
    {
      path: ROUTES.GenerateShippingLabelPage.path,
      name: ROUTES.GenerateShippingLabelPage.name,
      auth: true,
      authPage: ROUTES.LoginPage.name,
      component: GenerateShippingLabelPage,
    },
    {
      path: ROUTES.ManageListingsPage.path,
      name: ROUTES.ManageListingsPage.name,
      auth: true,
      authPage: ROUTES.LoginPage.name,
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: ROUTES.ManageListingsPage.path,
      name: ROUTES.ManageListingsPage.name,
      auth: true,
      authPage: ROUTES.LoginPage.name,
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: ROUTES.ManageClosetPage.path,
      name: ROUTES.ManageClosetPage.name,
      auth: true,
      authPage: ROUTES.LoginPage.name,
      component: ManageClosetPage,
      loadData: pageDataLoadingAPI.ManageClosetPage.loadData,
    },
    {
      path: ROUTES.ManageTradeInsPage.path,
      name: ROUTES.ManageTradeInsPage.name,
      auth: true,
      authPage: ROUTES.LoginPage.name,
      component: ManageTradeInsPage,
      loadData: pageDataLoadingAPI.ManageTradeInsPage.loadData,
    },
    {
      path: ROUTES.ManagePurchasesPage.path,
      name: ROUTES.ManagePurchasesPage.name,
      auth: true,
      authPage: ROUTES.LoginPage.name,
      component: ManagePurchasesPage,
      loadData: pageDataLoadingAPI.ManagePurchasesPage.loadData,
    },
    {
      path: ROUTES.ManagePurchasePage.path,
      name: ROUTES.ManagePurchasePage.name,
      auth: true,
      authPage: ROUTES.LoginPage.name,
      component: ManagePurchasesPage,
      loadData: pageDataLoadingAPI.ManagePurchasesPage.loadData,
    },
    {
      path: ROUTES.ManageSalesPage.path,
      name: ROUTES.ManageSalesPage.name,
      auth: true,
      authPage: ROUTES.LoginPage.name,
      component: ManageSalesPage,
      loadData: pageDataLoadingAPI.ManageSalesPage.loadData,
    },
    {
      path: ROUTES.ManageSalePage.path,
      name: ROUTES.ManageSalePage.name,
      auth: true,
      authPage: ROUTES.LoginPage.name,
      component: ManageSalesPage,
      loadData: pageDataLoadingAPI.ManageSalesPage.loadData,
    },
    {
      path: ROUTES.AccountSettingsPage.path,
      name: ROUTES.AccountSettingsPage.name,
      auth: true,
      authPage: ROUTES.LoginPage.name,
      component: () => <NamedRedirect name={ROUTES.ContactDetailsPage.name} />,
    },
    {
      path: ROUTES.ContactDetailsPage.path,
      name: ROUTES.ContactDetailsPage.name,
      auth: true,
      authPage: ROUTES.LoginPage.name,
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: ROUTES.PasswordChangePage.path,
      name: ROUTES.PasswordChangePage.name,
      auth: true,
      authPage: ROUTES.LoginPage.name,
      component: PasswordChangePage,
    },
    {
      path: ROUTES.StripePayoutPage.path,
      name: ROUTES.StripePayoutPage.name,
      auth: true,
      authPage: ROUTES.LoginPage.name,
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: ROUTES.StripePayoutOnboardingPage.path,
      name: ROUTES.StripePayoutOnboardingPage.name,
      auth: true,
      authPage: ROUTES.LoginPage.name,
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: ROUTES.PaymentMethodsPage.path,
      name: ROUTES.PaymentMethodsPage.name,
      auth: true,
      authPage: ROUTES.LoginPage.name,
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: ROUTES.TermsOfServicePage.path,
      name: ROUTES.TermsOfServicePage.name,
      component: TermsOfServicePage,
    },
    {
      path: ROUTES.PrivacyPolicyPage.path,
      name: ROUTES.PrivacyPolicyPage.name,
      component: PrivacyPolicyPage,
    },
    {
      path: ROUTES.CookiePolicyPage.path,
      name: ROUTES.CookiePolicyPage.name,
      component: CookiePolicyPage,
    },
    {
      path: ROUTES.SubprocessorsPage.path,
      name: ROUTES.SubprocessorsPage.name,
      component: SubprocessorsPage,
    },
    {
      path: ROUTES.FAQPage.path,
      name: ROUTES.FAQPage.name,
      component: FAQPage,
    },
    {
      path: ROUTES.NotFoundPage.path,
      name: ROUTES.NotFoundPage.name,
      component: NotFoundPage,
    },
    {
      path: ROUTES.AuthPageRedirect.path,
      name: ROUTES.AuthPageRedirect.name,
      component: AuthenticationRedirectPage,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: ROUTES.PasswordResetPage.path,
      name: ROUTES.PasswordResetPage.name,
      component: PasswordResetPage,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: ROUTES.EmailVerificationPage.path,
      name: ROUTES.EmailVerificationPage.name,
      auth: true,
      authPage: ROUTES.LoginPage.name,
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
  ];

  return isTreetShop ? treetShopRoutes : treetAppRoutes;
};

export default routeConfiguration;
