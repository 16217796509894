import React, { FC } from 'react';
import { useIconColor } from '../../../hooks/useIconColor';

interface IconViewLiveProps {
  className?: string;
  color?: string;
}

const IconViewLive: FC<IconViewLiveProps> = (props: IconViewLiveProps) => {
  const { className, color } = props;

  const { iconColor } = useIconColor();
  const fillColor = color || iconColor;

  return (
    <svg
      className={className}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 9H6V23H26V9ZM4 7V25H28V7H4Z"
        fill={fillColor}
      />
      <rect x="2" y="26" width="28" height="2" fill={fillColor} />
      <path
        d="M18.4806 15.1317C19.1524 15.5156 19.1524 16.4843 18.4806 16.8682L14.4961 19.145C13.8295 19.526 13 19.0446 13 18.2768L13 13.7232C13 12.9553 13.8295 12.474 14.4961 12.8549L18.4806 15.1317Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default IconViewLive;
