/* eslint-disable import/prefer-default-export */
import { useSelector } from 'react-redux';
import bowser from 'bowser';

// TODO (SY|TREET-608): Refactor code to use this hook
export function useIsMobile() {
  const userAgent = useSelector<any>((state) => state.initial.userAgent) as string;

  // If no window information, use userAgent from state
  if (typeof window === 'undefined') {
    if (userAgent) {
      const browser = bowser.getParser(userAgent);
      return browser.getPlatformType() === 'mobile';
    }
    // Fallback to false if no userAgent
    return false;
  }

  // Otherwise, use Bowser to detect if the platform is mobile
  if (!window.navigator.userAgent) {
    return false;
  }
  const browser = bowser.getParser(window.navigator.userAgent);
  return browser.getPlatformType() === 'mobile';
}
