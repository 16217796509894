import { Builder } from '@builder.io/react';
import classNames from 'classnames';
import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { IconCheckmark, IconSpinner, TypographyWrapper } from '..';
import { defaultTreetStyles } from '../../shopConfig/config';
import { useIsMobile } from '../../hooks/useIsMobile';
import { findRouteByRouteName } from '../../util/routes';
import { useShopCss } from '../../hooks/useShopCss';
import { IButton } from '../../types/contentful/types.generated';
import { useRouteConfiguration } from '../../hooks/useRouteConfiguration';
import { builderButtonInputs } from './BuilderButtonInputs';
import { TypographyFormat } from '../TypographyWrapper/TypographyWrapper';
import css from './Button.module.css';

export enum ButtonVariant {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Inline = 'INLINE',
  Danger = 'DANGER',
  SocialLogin = 'SOCIAL_LOGIN',
  Custom = 'CUSTOM',
}
export interface ButtonProps {
  rootClassName?: string;
  className?: string;
  spinnerClassName?: string;
  checkmarkClassName?: string;
  inProgress?: boolean;
  ready?: boolean;
  disabled?: boolean;
  enforcePagePreloadFor?: string;
  children?: React.ReactNode;
  onClick?: any;
  type?: 'button' | 'submit' | 'reset' | undefined;
  variant?: ButtonVariant;
  style?: any;
}

// CSS to override if the button config is pulled from contentful
export const getOverrideButtonStyles = (
  buttonConfig: IButton,
  disabled: boolean,
  hover: boolean,
  isMobile: boolean
) => {
  const {
    font,
    fontHoverColor,
    fontDisabledColor,
    backgroundColor,
    backgroundColorHover,
    backgroundColorDisabled,
    border,
    borderHover,
    padding,
    borderRadius,
  } = buttonConfig || {};
  const {
    fontSizeSmall,
    fontSize,
    letterSpacing,
    lineHeight,
    textTransform,
    fontWeight,
    color,
    fontFamily,
    style,
  } = font || {};

  let styles: CSSProperties = {
    fontSize: isMobile ? fontSizeSmall || fontSize : fontSize,
    fontFamily: `${fontFamily || ['sofiapro', '"Helvetica"', 'Arial', 'sans-serif'].join(',')}`,
    letterSpacing,
    lineHeight,
    textTransform,
    fontWeight,
    padding,
    minHeight: padding ? 0 : undefined,
    borderRadius,
    ...style,
  };

  // Disabled State - to keep it simple, just use common disabled styles
  if (disabled) {
    styles = {
      ...styles,
      color: fontDisabledColor || defaultTreetStyles.gray00,
      backgroundColor: backgroundColorDisabled || defaultTreetStyles.gray20,
      border: 'none',
      cursor: 'not-allowed',
    };
    // Hover State - if nothing is specified, just default to the regular styles
  } else if (hover) {
    styles = {
      ...styles,
      color: fontHoverColor || color,
      backgroundColor: backgroundColorHover || backgroundColor,
      border: borderHover || border,
    };
    // Normal State
  } else {
    styles = {
      ...styles,
      color,
      backgroundColor,
      border,
    };
  }

  return styles;
};

const Button: FC<ButtonProps & React.HTMLProps<HTMLButtonElement>> = (props) => {
  const [mounted, setMounted] = useState(false);
  const [hover, setHover] = useState(false);

  const isMobile = useIsMobile();
  const shopCss = useShopCss();
  const routes = useRouteConfiguration();

  useEffect(() => {
    setMounted(true);
  }, []);

  const {
    children,
    className,
    rootClassName,
    spinnerClassName,
    checkmarkClassName,
    inProgress = false,
    ready = false,
    disabled = false,
    enforcePagePreloadFor,
    type = 'button',
    variant = ButtonVariant.Primary,
    style,
    ...rest
  } = props;

  const { primaryButton, secondaryButton } = shopCss;
  const buttonConfig = variant === ButtonVariant.Secondary ? secondaryButton : primaryButton;

  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, className, {
    [css.ready]: ready,
    [css.inProgress]: inProgress,
  });

  // All buttons are disabled until the component is mounted. This
  // prevents e.g. being able to submit forms to the backend before
  // the client side is handling the submit.
  const buttonDisabled = mounted ? disabled : true;

  let overrideStyles: CSSProperties = {};
  // When we should use styles specified from contentful
  if (
    shopCss &&
    [ButtonVariant.Primary, ButtonVariant.Secondary, ButtonVariant.Danger].includes(variant)
  ) {
    overrideStyles = getOverrideButtonStyles(buttonConfig, buttonDisabled, hover, isMobile);
  }

  let content;
  if (inProgress) {
    content = (
      <Box display="flex" alignItems="center" justifyContent="center">
        <IconSpinner
          style={{ stroke: overrideStyles?.color }}
          rootClassName={spinnerClassName || css.spinner}
        />
      </Box>
    );
  } else if (ready) {
    content = (
      <Box display="flex" alignItems="center" justifyContent="center">
        <IconCheckmark
          style={{ stroke: overrideStyles?.color }}
          rootClassName={checkmarkClassName || css.checkmark}
        />
      </Box>
    );
  } else {
    content = children;
  }

  const onOverButtonFn = (pageName: string) => () => {
    // Enforce preloading of given page (loadable component)
    const { component: Page } = findRouteByRouteName(pageName, routes);
    // Loadable Component has a "preload" function.
    if (Page.preload) {
      Page.preload();
    }
  };

  const onOverButton = enforcePagePreloadFor ? onOverButtonFn(enforcePagePreloadFor) : null;
  const onOverButtonMaybe = onOverButton
    ? {
        onMouseOver: onOverButton,
        onTouchStart: onOverButton,
      }
    : {};

  // Needed because we can't apply !important in inline styles
  let overrideRef;

  if (variant === ButtonVariant.Danger) {
    // TODO (sonia-y | TREET-1281): This is very repetitive but once everyone is on shopCss, we can feed these as override styles into getOverrideButtonStyles instead
    overrideStyles = {
      ...overrideStyles,

      border: 'none',
    };

    if (buttonDisabled) {
      overrideStyles = {
        ...overrideStyles,

        color: defaultTreetStyles.gray00,
        backgroundColor: defaultTreetStyles.gray20,
        cursor: 'not-allowed',
      };
    } else if (hover) {
      overrideStyles = {
        ...overrideStyles,

        color: 'white',
        backgroundColor: defaultTreetStyles.red60,
      };
    } else {
      overrideStyles = {
        ...overrideStyles,

        color: 'white',
        backgroundColor: defaultTreetStyles.red80,
      };
    }
  }

  // This is the only way to set the !important flag in React
  // TODO (sonia-y | TREET-1281): Remove this piece of code once we no longer use the fonts configured from config.js
  if (overrideStyles.fontFamily) {
    overrideRef = (element: HTMLButtonElement) => {
      if (element)
        element.style.setProperty('font-family', overrideStyles.fontFamily as string, 'important');
    };
  }

  return (
    // button type should be passed in by the parents using this
    <button
      className={classes}
      {...onOverButtonMaybe}
      {...rest}
      // eslint can't detect dynamic assignment
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={buttonDisabled}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onFocus={() => setHover(true)}
      onBlur={() => setHover(false)}
      style={{ ...overrideStyles, ...style }}
      ref={overrideRef}
    >
      {content}
    </button>
  );
};

export const InlineTextButton = (props: any) => {
  const {
    children,
    rootClassName,
    style,
    typographyVariant,
    typographyFormat,
    typographyWeight,
    typographyOverrides,
    ...rest
  } = props;
  const classes = classNames(rootClassName || css.inlineTextButtonRoot, css.inlineTextButton);

  const shopCss = useShopCss();
  const { primaryButton } = shopCss;
  const { padding } = primaryButton;

  return (
    <Button
      {...rest}
      rootClassName={classes}
      variant={ButtonVariant.Inline}
      style={{ padding, paddingLeft: 0, paddingRight: 0, ...style }}
    >
      <TypographyWrapper
        variant={typographyVariant || 'body1'}
        format={typographyFormat || TypographyFormat.HoverUnderlined}
        weight={typographyWeight}
        typographyOverrides={{ display: 'inline', ...typographyOverrides }}
      >
        {children}
      </TypographyWrapper>
    </Button>
  );
};
InlineTextButton.displayName = 'InlineTextButton';

export const SocialLoginButton = (props: any) => {
  const classes = classNames(props.rootClassName || css.socialButtonRoot);
  return <Button {...props} rootClassName={classes} variant={ButtonVariant.SocialLogin} />;
};
SocialLoginButton.displayName = 'SocialLoginButton';

Builder.registerComponent(Button, {
  name: 'Button',
  inputs: builderButtonInputs,
});

export default Button;
