import { CurrentUser } from '../types/sharetribe/currentUser';
import { isDev, isTest } from './envHelpers';
import { getUrlSearchParams, PRESERVED_SEARCH_PARAMS } from './urlHelpers';

export enum Feature {
  ManagePages = 'MANAGE_PAGES',
  FrenzySearch = 'FRENZY_SEARCH',
  RecommendedListings = 'RECOMMENDED_LISTINGS',
  ForceShopLaunch = 'FORCE_SHOP_LAUNCH',
  TreetShop = 'TREET_SHOP',
  FollowingPage = 'FOLLOWING_PAGE',
  LandingPageSubscribeModal = 'LANDING_PAGE_SUBSCRIBE_MODAL',
  EnabledCustomerExperiences = 'ENABLED_CUSTOMER_EXPERIENCES',
  SeelReturnInsurance = 'SEEL_RETURN_INSURANCE',
  BackgroundRemoval = 'BACKGROUND_REMOVAL',
  GuestListing = 'GUEST_LISTING',
  ProgressBar = 'PROGRESS_BAR',
  ChooseStockPhotos = 'CHOOSE_STOCK_PHOTOS',
  Zendesk = 'ZENDESK',
  InSearchOf = 'IN_SEARCH_OF',
  QRCode = 'QR_CODE',
  RemoveInvalidImages = 'REMOVE_INVALID_IMAGES',
  MultiSeller = 'MULTI_SELLER',
  CookieConsent = 'COOKIE_CONSENT',
  EmailManagement = 'EMAIL_MANAGEMENT',
  BrandListingApprovalTool = 'BRAND_LISTING_APPROVAL_TOOL',
  Yotpo = 'YOTPO',
  ConsolidatedListings = 'CONSOLIDATED_LISTINGS',
  UploadcareSmartResize = 'UPLOADCARE_SMART_RESIZE',
  ListingCardHover = 'LISTING_CARD_HOVER',
  BuyerSellerFeedback = 'BUYER_SELLER_FEEDBACK',
  SearchBarV2 = 'SEARCH_BAR_V2',
  UploadcareSelfOverlay = 'UPLOADCARE_SELF_OVERLAY',
  StripeExpressCheckoutElement = 'STRIPE_EXPRESS_CHECKOUT_ELEMENT',
  CSBuy = 'CS_Buy',
  IntegrationsMarketplace = 'INTEGRATIONS_MARKETPLACE',
  BrandHealthDashboard = 'BRAND_HEALTH_DASHBOARD',
  MainSiteBanner = 'MAIN_SITE_BANNER',
}

const getParamValues = (urlSearchParams: URLSearchParams, feature: string) =>
  urlSearchParams.has(feature)
    ? urlSearchParams
        .get(feature)! // Can force non-null because we check it in the line above
        .toLowerCase()
        .split(',')
    : [];

export const isFeatureEnabled = (
  feature: Feature,
  treetId: string,
  currentUser?: CurrentUser | null
): boolean => {
  // Force enable features through query params
  const urlSearchParams = getUrlSearchParams();
  const enabledFeatures = getParamValues(urlSearchParams, PRESERVED_SEARCH_PARAMS.EnabledFeatures);
  const disabledFeatures = getParamValues(
    urlSearchParams,
    PRESERVED_SEARCH_PARAMS.DisabledFeatures
  );

  if (enabledFeatures.includes(feature.toLowerCase())) {
    return true;
  }

  if (disabledFeatures.includes(feature.toLowerCase())) {
    return false;
  }

  switch (feature) {
    case Feature.RecommendedListings:
      return (
        currentUser?.attributes?.profile?.publicData?.isTreet ||
        process.env.REACT_APP_FEATURE_RECOMMENDED_LISTINGS_LAUNCHED === 'true' ||
        !!process.env.REACT_APP_FEATURE_RECOMMENDED_LISTINGS_LAUNCHED?.split(',').includes(treetId)
      );
    case Feature.FrenzySearch:
      return !process.env.REACT_APP_FEATURE_FRENZY_SEARCH_EXCLUDED?.split(',').includes(treetId);
    case Feature.TreetShop:
      return process.env.REACT_APP_FEATURE_TREET_SHOP_LAUNCHED === 'true';
    case Feature.FollowingPage:
      return process.env.REACT_APP_FEATURE_FOLLOWING_PAGE_LAUNCHED === 'true';
    case Feature.LandingPageSubscribeModal:
      return !process.env.REACT_APP_FEATURE_LANDING_PAGE_SUBSCRIBE_MODAL_EXCLUDED?.split(
        ','
      ).includes(treetId);
    case Feature.SeelReturnInsurance:
      return (
        !!process.env.REACT_APP_FEATURE_SEEL_RETURN_INSURANCE_LAUNCHED?.split(',').includes(
          treetId
        ) || process.env.REACT_APP_FEATURE_SEEL_RETURN_INSURANCE_LAUNCHED === 'true'
      );
    case Feature.GuestListing:
      return process.env.REACT_APP_FEATURE_GUEST_LISTING === 'true';
    case Feature.ProgressBar:
      return process.env.REACT_APP_FEATURE_PROGRESS_BAR === 'true';
    case Feature.ChooseStockPhotos:
      return process.env.REACT_APP_FEATURE_CHOOSE_STOCK_PHOTOS === 'true';
    case Feature.Zendesk:
      return (
        isTest ||
        isDev ||
        process.env.REACT_APP_FEATURE_ZENDESK === 'true' ||
        !!process.env.REACT_APP_FEATURE_ZENDESK?.split(',').includes(treetId)
      );
    case Feature.InSearchOf:
      return process.env.REACT_APP_FEATURE_IN_SEARCH_OF === 'true';
    case Feature.QRCode:
      return process.env.REACT_APP_FEATURE_QR_CODE === 'true';
    case Feature.RemoveInvalidImages:
      return (
        !!process.env.REACT_APP_FEATURE_REMOVE_INVALID_IMAGES?.split(',').includes(treetId) ||
        process.env.REACT_APP_FEATURE_REMOVE_INVALID_IMAGES === 'true'
      );
    case Feature.MultiSeller:
      return process.env.REACT_APP_FEATURE_MULTI_SELLER === 'true';
    case Feature.CookieConsent:
      return (
        !!process.env.REACT_APP_FEATURE_COOKIE_CONSENT?.split(',').includes(treetId) ||
        process.env.REACT_APP_FEATURE_COOKIE_CONSENT === 'true'
      );
    case Feature.EmailManagement:
      return process.env.REACT_APP_FEATURE_EMAIL_MANAGEMENT === 'true';
    case Feature.BrandListingApprovalTool:
      return !!process.env.REACT_APP_FEATURE_BRAND_LISTING_APPROVAL_TOOL?.split(',').includes(
        treetId
      );
    case Feature.ConsolidatedListings:
      return (
        !!process.env.REACT_APP_FEATURE_CONSOLIDATED_LISTINGS?.split(',').includes(treetId) ||
        process.env.REACT_APP_FEATURE_CONSOLIDATED_LISTINGS === 'true'
      );
    case Feature.UploadcareSmartResize:
      return process.env.REACT_APP_UPLOADCARE_SMART_RESIZE === 'true';
    case Feature.ListingCardHover:
      return process.env.REACT_APP_LISTING_CARD_HOVER === 'true';
    case Feature.BuyerSellerFeedback:
      return (
        process.env.REACT_APP_BUYER_SELLER_FEEDBACK?.split(',').includes(treetId) ||
        process.env.REACT_APP_BUYER_SELLER_FEEDBACK === 'true'
      );
    case Feature.SearchBarV2:
      return process.env.REACT_APP_FEATURE_SEARCH_BAR_V2 === 'true';
    case Feature.UploadcareSelfOverlay:
      return process.env.REACT_APP_UPLOADCARE_SELF_OVERLAY === 'true';
    case Feature.StripeExpressCheckoutElement:
      return (
        !!process.env.REACT_APP_STRIPE_EXPRESS_CHECKOUT_ELEMENT?.split(',').includes(treetId) ||
        process.env.REACT_APP_STRIPE_EXPRESS_CHECKOUT_ELEMENT === 'true'
      );
    case Feature.CSBuy:
      return process.env.REACT_APP_FEATURE_CS_BUY === 'true';
    case Feature.IntegrationsMarketplace:
      return process.env.REACT_APP_FEATURE_INTEGRATIONS_MARKETPLACE === 'true';
    case Feature.BrandHealthDashboard:
      return process.env.REACT_APP_FEATURE_BRAND_HEALTH_DASHBOARD === 'true';
    case Feature.MainSiteBanner:
      return (
        !!process.env.REACT_APP_FEATURE_MAIN_SITE_BANNER?.split(',').includes(treetId) ||
        process.env.REACT_APP_FEATURE_MAIN_SITE_BANNER === 'true'
      );
    default:
      return false;
  }
};

export const getFeatureValue = (feature: Feature): string[] | null => {
  const urlSearchParams = getUrlSearchParams();

  switch (feature) {
    case Feature.EnabledCustomerExperiences: {
      const experiences = getParamValues(
        urlSearchParams,
        PRESERVED_SEARCH_PARAMS.EnabledCustomerExperiences
      );
      return experiences.map((experience) => experience.toUpperCase());
    }
    default:
      return null;
  }
};
