import { BuilderSections } from '../util/builder';

export const PAGE_TO_PATH = {
  ManagePurchasesPage: '/purchases',
  ManageSalesPage: '/sales',
  ManageListingsPage: '/listings',
  ManageClosetPage: '/closet',
  ManageTradeInsPage: '/trade-ins',
  ProfileSettingsPage: '/profile-settings',
  AccountSettingsPage: '/account',
  NotificationSettingsPage: '/notification-settings',
  AboutBasePage: '/about',
  AboutTreetPage: '/about-treet',
  SearchPage: '/',
  ListingBasePage: '/l',
  FavoritesPage: '/favorites',
  ProfileBasePage: '/u',
  AdminBasePage: '/admin',
};

export const ROUTES = {
  NotFoundPage: { path: '/notfound', name: 'NotFoundPage' },
  FAQPage: { path: '/faq', name: 'FAQPage' },
  AuthPageRedirect: { path: '/redirect', name: 'AuthPageRedirect' },
  PasswordResetPage: { path: '/reset-password', name: 'PasswordResetPage' },
  EmailVerificationPage: { path: '/verify-email', name: 'EmailVerificationPage' },
  TreetShopLandingPage: { path: '/', name: 'TreetShopLandingPage' },
  TreetShopLandingPagePath: { path: '/*', name: 'TreetShopLandingPagePath' },
  SearchPage: { path: '/', name: 'SearchPage' },
  LandingPage: { path: '/', name: 'LandingPage' },
  DeprecatedSearchPage: { path: '/s', name: 'DeprecatedSearchPage' },
  AboutTreetPage: { path: PAGE_TO_PATH.AboutTreetPage, name: 'AboutTreetPage' },
  TreetProtectionPage: { path: '/protections', name: 'TreetProtectionPage' },
  AboutBasePage: { path: PAGE_TO_PATH.AboutBasePage, name: 'AboutBasePage' },
  AboutPage: { path: '/about/:tab', name: 'AboutPage' },
  AdminBasePage: { path: '/admin', name: 'AdminBasePage' },
  AdminPage: { path: '/admin/:tab', name: 'AdminPage' },
  AdminPageVariant: { path: '/admin/:tab/:id', name: 'AdminPageVariant' },
  AdminPagePane: { path: '/admin/:tab/:id/:pane', name: 'AdminPagePane' },
  ListingBasePage: { path: '/l', name: 'ListingBasePage' },
  ListingPage: { path: '/l/:slug/:id', name: 'ListingPage' },
  CheckoutPage: { path: '/checkout', name: 'CheckoutPage' },
  ListingPageVariant: { path: '/l/:slug/:id/:variant', name: 'ListingPageVariant' },
  NewListingPage: { path: '/l/new', name: 'NewListingPage' },
  EditListingPage: { path: '/l/:slug/:id/:type/:tab', name: 'EditListingPage' },
  ListingPageCanonical: { path: '/l/:id', name: 'ListingPageCanonical' },
  ProfileBasePage: { path: '/u', name: 'ProfileBasePage' },
  ProfilePage: { path: '/u/:id', name: 'ProfilePage' },
  ProfileSettingsPage: { path: PAGE_TO_PATH.ProfileSettingsPage, name: 'ProfileSettingsPage' },
  NotificationSettingsPage: {
    path: PAGE_TO_PATH.NotificationSettingsPage,
    name: 'NotificationSettingsPage',
  },
  LoginPage: { path: '/login', name: 'LoginPage' },
  SignupPage: { path: '/signup', name: 'SignupPage' },
  PasswordRecoveryPage: { path: '/recover-password', name: 'PasswordRecoveryPage' },
  OrderSuccessPage: { path: '/order/:id/success', name: 'OrderSuccessPage' },
  FavoritesPage: { path: '/favorites', name: 'FavoritesPage' },
  GenerateShippingLabelPage: {
    path: '/generate-shipping-label/:id',
    name: 'GenerateShippingLabelPage',
  },
  ManageListingsPage: { path: PAGE_TO_PATH.ManageListingsPage, name: 'ManageListingsPage' },
  ManageClosetPage: { path: PAGE_TO_PATH.ManageClosetPage, name: 'ManageClosetPage' },
  ManageTradeInsPage: { path: PAGE_TO_PATH.ManageTradeInsPage, name: 'ManageTradeInsPage' },
  ManagePurchasesPage: { path: PAGE_TO_PATH.ManagePurchasesPage, name: 'ManagePurchasesPage' },
  ManagePurchasePage: {
    path: `${PAGE_TO_PATH.ManagePurchasesPage}/:id`,
    name: 'ManagePurchasePage',
  },
  ManageSalesPage: { path: PAGE_TO_PATH.ManageSalesPage, name: 'ManageSalesPage' },
  ManageSalePage: { path: `${PAGE_TO_PATH.ManageSalesPage}/:id`, name: 'ManageSalePage' },
  AccountSettingsPage: { path: PAGE_TO_PATH.AccountSettingsPage, name: 'AccountSettingsPage' },
  ContactDetailsPage: {
    path: `${PAGE_TO_PATH.AccountSettingsPage}/contact-details`,
    name: 'ContactDetailsPage',
  },
  PasswordChangePage: {
    path: `${PAGE_TO_PATH.AccountSettingsPage}/change-password`,
    name: 'PasswordChangePage',
  },
  StripePayoutPage: {
    path: `${PAGE_TO_PATH.AccountSettingsPage}/payments`,
    name: 'StripePayoutPage',
  },
  StripePayoutOnboardingPage: {
    path: `${PAGE_TO_PATH.AccountSettingsPage}/payments/:returnURLType`,
    name: 'StripePayoutOnboardingPage',
  },
  PaymentMethodsPage: {
    path: `${PAGE_TO_PATH.AccountSettingsPage}/payment-methods`,
    name: 'PaymentMethodsPage',
  },
  TermsOfServicePage: { path: '/terms-of-service', name: 'TermsOfServicePage' },
  PrivacyPolicyPage: { path: '/privacy-policy', name: 'PrivacyPolicyPage' },
  CookiePolicyPage: { path: '/cookie-policy', name: 'CookiePolicyPage' },
  SubprocessorsPage: { path: '/subprocessors', name: 'SubprocessorsPage' },
};

// Include Builder sections here that should be SSRed for that page (usually anything
// above the fold!).
// By default, BuilderSections.TopbarContent and BuilderSections.TopbarMobileContent
// will be fetched on every page except the admin page.
export const PATH_TO_BUILDER_SECTIONS = {
  [PAGE_TO_PATH.AboutBasePage]: [
    BuilderSections.AboutPageHeroContent,
    BuilderSections.AboutPageNavbarContent,
    BuilderSections.AboutPageInfoTabContent,
    BuilderSections.ListingCardDetails,
  ],
  [PAGE_TO_PATH.SearchPage]: [
    BuilderSections.LandingPageContent,
    BuilderSections.ListingCardDetails,
  ],
  [PAGE_TO_PATH.ListingBasePage]: [
    BuilderSections.ListingPageContent,
    BuilderSections.ListingCardDetails,
    BuilderSections.ListingPageTitleAndVariantDetailsContent,
  ],
  [PAGE_TO_PATH.ManageListingsPage]: [BuilderSections.ListingCardDetails],
  [PAGE_TO_PATH.ManageTradeInsPage]: [BuilderSections.ListingCardDetails],
  [PAGE_TO_PATH.ManageClosetPage]: [BuilderSections.ListingCardDetails],
  [PAGE_TO_PATH.FavoritesPage]: [BuilderSections.ListingCardDetails],
  [PAGE_TO_PATH.ProfileBasePage]: [BuilderSections.ListingCardDetails],
};

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];
