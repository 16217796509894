import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { IconArrowLeft, NamedLink, TypographyWrapper } from '..';
import { useShopConfig } from '../../hooks/shopConfig';
import { Participant } from '../../types/apollo/generated/types.generated';
import { BundleInfo } from '../../types/models/bundle';
import { getFormattedDate } from '../../util/dates';
import css from './ManageBundlesContainer.module.css';
import ThreeDotsIconButton from './ThreeDotsIconButton';
import { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';

interface ManageBundlesTopbarProps {
  bundle: BundleInfo;
  rootPageName: string;
  participant: Participant;
  onCancelClick?: (bundle: BundleInfo) => void;
}

const ManageBundlesTopbar: FC<ManageBundlesTopbarProps> = (props: ManageBundlesTopbarProps) => {
  const { bundle, rootPageName, participant, onCancelClick } = props;
  const { css: brandCss } = useShopConfig();

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      className={css.topBar}
      bgcolor={brandCss?.backgroundColor || 'white'}
      p={1}
    >
      <NamedLink name={rootPageName}>
        <IconArrowLeft className={css.backIcon} />
      </NamedLink>
      <h3>
        <TypographyWrapper variant="h1" weight={TypographyWeight.Bold} isDynamicValue>
          {getFormattedDate(bundle.createdAt)}
        </TypographyWrapper>
      </h3>
      <ThreeDotsIconButton
        participant={participant}
        bundle={bundle}
        onCancelClick={onCancelClick}
      />
    </Box>
  );
};
export default ManageBundlesTopbar;
