import { ListingWithImages, OwnListingWithImages } from '../types/sharetribe/listing';
import {
  GetFeaturedImageInputs,
  getFeaturedListingImageHelper,
  getSharetribeImagesForListing,
  getStockImagesForListing,
  getUploadcareImagesForListing,
} from '../util/listings/listingImages';
import { shouldRenderBackgroundBlurFn } from '../containers/ListingPage/SectionImages.utils';
import { useShopConfig } from './shopConfig';
import { getUploadcareImagePreviewUrl } from '../util/uploadcare';

type ExtendedGetFeaturedImageInputs = GetFeaturedImageInputs & {
  imageIndex: number;
};

// Keep core logic in sync with server/utils/listings.ts
export const getFeaturedListingImage = (params: GetFeaturedImageInputs) => {
  const {
    firstStockImageSrc,
    firstSellerImage,
    firstSellerImageSrc,
    shouldShowStockImage,
    isUploadcareEnabled,
  } = getFeaturedListingImageHelper(params);

  const { listing } = params;

  const { images } = listing?.attributes?.publicData || {};

  if (shouldShowStockImage && firstStockImageSrc) {
    const shouldRenderBackgroundBlur = shouldRenderBackgroundBlurFn(firstStockImageSrc, images);
    const maybeOptimizedImageUrl = getUploadcareImagePreviewUrl(firstStockImageSrc) as string;
    return { src: maybeOptimizedImageUrl, shouldRenderBackgroundBlur };
  }

  if (isUploadcareEnabled && firstSellerImageSrc) {
    const shouldRenderBackgroundBlur = shouldRenderBackgroundBlurFn(firstSellerImageSrc, images);
    const maybeOptimizedImageUrl = getUploadcareImagePreviewUrl(firstSellerImageSrc) as string;
    return {
      src: maybeOptimizedImageUrl,
      shouldRenderBackgroundBlur,
    };
  }

  return { image: firstSellerImage };
};

/**
 * Returns the featured image for the listing
 */
export const useFeaturedListingImage = (
  listing: ListingWithImages | OwnListingWithImages,
  forceHideStockImages?: boolean
) => {
  const {
    chooseStockImagesOption,
    maxStockImages,
    listingFlowConfig: { featuredListingImageType },
    imageRatio,
  } = useShopConfig();

  return getFeaturedListingImage({
    showStockImages: !forceHideStockImages && maxStockImages > 0,
    featuredListingImageType,
    chooseStockImagesOption,
    listing,
    imageRatio,
  });
};

// TODO: (HM |TREET-6824) refactor getSpecificListingImage and getFeaturedListingImage
/*
  returns a specific image from the ordered list of images for a listing,
  taking into account the featured image rules
*/
export const getSpecificListingImage = (params: ExtendedGetFeaturedImageInputs) => {
  if (!params?.listing) return null;
  const { chooseStockImagesOption, listing, imageIndex } = params;
  const { shouldShowStockImage, isUploadcareEnabled } = getFeaturedListingImageHelper(params);

  const getAllStockImages = () => getStockImagesForListing(listing, chooseStockImagesOption) || [];

  const getAllSellerImages = () =>
    isUploadcareEnabled
      ? getUploadcareImagesForListing(listing)
      : getSharetribeImagesForListing(listing);

  // Get all images: stock images followed by seller images
  const stockImages = shouldShowStockImage ? getAllStockImages() : [];
  const sellerImages = getAllSellerImages();
  const allImages = [...stockImages, ...sellerImages];

  // Get the requested image by index
  const selectedImageSrc = allImages[imageIndex];

  if (selectedImageSrc) {
    const { images } = listing?.attributes?.publicData || {};
    const shouldRenderBackgroundBlur = shouldRenderBackgroundBlurFn(selectedImageSrc, images);
    const optimizedImageUrl = getUploadcareImagePreviewUrl(selectedImageSrc) as string;

    return { src: optimizedImageUrl || selectedImageSrc, shouldRenderBackgroundBlur };
  }

  // If no image found, return null
  return null;
};

// TODO: (HM |TREET-6824) refactor useSpecificListingImage and useFeaturedListingImage
export const useSpecificListingImage = (
  listing: ListingWithImages | OwnListingWithImages,
  imageIndex: number,
  forceHideStockImages?: boolean
) => {
  const {
    chooseStockImagesOption,
    maxStockImages,
    listingFlowConfig: { featuredListingImageType },
    imageRatio,
  } = useShopConfig();

  return getSpecificListingImage({
    showStockImages: !forceHideStockImages && maxStockImages > 0 && maxStockImages > imageIndex,
    featuredListingImageType,
    chooseStockImagesOption,
    listing,
    imageRatio,
    imageIndex,
  });
};
