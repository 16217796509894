// Based on the example from rff: https://codesandbox.io/s/github/final-form/react-final-form/tree/master/examples/wizard?file=/Wizard.js:854-950

import { Box, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import React, { FC, useState } from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Button, DialogWithCloseButton, Error, InlineTextButton } from '..';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import { DisputeFormData } from '../../types/forms/disputeFormData';
import { BUNDLE_ITEM_IDS_FIELD_NAME, DISPUTES_INFO_FIELD_PREFIX, FORM_NAME } from './constants';
import css from './DisputeOrVerifyTradeInModal.module.css';

interface DisputeOrVerifyTradeInModalWizardProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: DisputeFormData) => void;
  onVerify: () => void;
  isTradeIn: boolean;
  isReturn: boolean;
  children?: React.ReactNode;
  hasErrorForReturns?: boolean;
  isLoadingForReturns?: boolean;
}

const DisputeOrVerifyTradeInModalWizard: FC<DisputeOrVerifyTradeInModalWizardProps> = (props) => {
  const {
    open,
    onClose,
    children,
    onSubmit,
    onVerify,
    isTradeIn,
    isReturn,
    hasErrorForReturns,
    isLoadingForReturns,
  } = props;

  const [page, setPage] = useState<number>(0);
  const [formValues, setFormValues] = useState({});
  const { isTradeInAdmin } = useCurrentUserPermissions();

  const activePage = React.Children.toArray(children)[page];
  const isFirstPage = page === 0;
  const isLastPage = page === React.Children.count(children) - 1;
  const isTradeInFirstPage = isTradeIn && isTradeInAdmin && isFirstPage && isLastPage;

  const nextPage = (values: Partial<DisputeFormData>) => {
    setPage(Math.min(page + 1, React.Children.count(children) - 1));
    setFormValues(values);
  };

  const prevPage = () => {
    setPage(Math.max(page - 1, 0));
  };

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  const validate = (values: Partial<DisputeFormData>) => {
    if (React.isValidElement(activePage)) {
      return activePage?.props.validate ? activePage.props.validate(values) : {};
    }
    return undefined;
  };

  const onFormSubmit = (values: Partial<DisputeFormData>) => {
    if (isTradeInFirstPage) return onVerify();

    if (isLastPage) {
      return onSubmit(values as DisputeFormData); // can only submit if all values are present
    }

    return nextPage(values);
  };

  const showDialogActions = !isReturn || (!hasErrorForReturns && !isLoadingForReturns);

  return (
    <Form initialValues={formValues} validate={validate} onSubmit={onFormSubmit}>
      {({ handleSubmit, invalid, submitting, values, form }) => (
        <form id={FORM_NAME} onSubmit={handleSubmit}>
          {/*  If any bundle items were unselected, we want to make sure those values are removed from the form state */}
          <OnChange name={BUNDLE_ITEM_IDS_FIELD_NAME}>
            {(bundleItemIds: string[]) => {
              Object.keys(values.disputesInfo || {}).forEach((bundleItemId) => {
                if (!bundleItemIds.includes(bundleItemId)) {
                  const newDisputesInfo = cloneDeep(values.disputesInfo) || {};
                  delete newDisputesInfo[bundleItemId];
                  form.change(DISPUTES_INFO_FIELD_PREFIX, newDisputesInfo);
                }
              });
            }}
          </OnChange>
          <DialogWithCloseButton
            open={open}
            onClose={() => {
              form.reset({});
              setPage(0);
              onClose();
            }}
            scroll="paper"
            titleText={
              isTradeIn && isTradeInAdmin
                ? 'Issue Credit'
                : isReturn
                ? 'Return Inquiry'
                : 'Order Inquiry'
            }
            fullWidth
            maxWidth="sm"
            headerClassName={css.dialogHeader}
          >
            <DialogContent>
              {isReturn && hasErrorForReturns ? (
                <Box mb={3}>
                  <Error>
                    Unfortunately an error has occurred. Please contact support@treet.co.
                  </Error>
                </Box>
              ) : (
                activePage
              )}
            </DialogContent>

            {showDialogActions && (
              <DialogActions>
                <Grid container spacing={2} justify="space-between" alignItems="center">
                  <Grid item xs={5}>
                    <Box px={2}>
                      <InlineTextButton
                        className={classNames(css.previousButton, { [css.hidden]: page === 0 })}
                        type="button"
                        onClick={prevPage}
                      >
                        <Typography variant="body1">Previous</Typography>
                      </InlineTextButton>
                    </Box>
                  </Grid>
                  <Grid item xs={5}>
                    <Box p={2}>
                      <Button
                        type="submit"
                        form={FORM_NAME}
                        disabled={!isTradeInFirstPage && (invalid || submitting)}
                        inProgress={submitting}
                      >
                        {isLastPage ? (isTradeInFirstPage ? 'Verify All' : 'Submit') : 'Next'}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </DialogActions>
            )}
          </DialogWithCloseButton>
        </form>
      )}
    </Form>
  );
};

export const WizardPage: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div>{children}</div>
);
export default DisputeOrVerifyTradeInModalWizard;
