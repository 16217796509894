import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';

const inheritcoSizes = [
  'XS',
  'XS/S',
  'S',
  'S/M',
  'M',
  'M/L',
  'L',
  'L/XL',
  'XL',
  'XXL',
  '1X',
  '1XL',
  '2X',
  '2XL',
  '2XL/3XL',
  '3X',
  '3XL',
  '4XL',
  '1',
  '2',
  '3',
  '4',
  '4/6',
  '5',
  '5/6',
  '5.5',
  '6',
  '6.5',
  '7',
  '7/8',
  '7.5',
  '8',
  '8/10',
  '8.5',
  '9',
  '9/10',
  '9.5',
  '10',
  '10/12',
  '11',
  '11/12',
  '12',
  '12/14',
  '13',
  '13/14',
  '14',
  '16',
  '18',
  '20',
  '22',
  '24',
];

export const inheritcoShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getSizeFilterConfig({ sizes: inheritcoSizes }),
    getBrandDirectFilterConfig(),
  ],
};
