/* eslint-disable import/prefer-default-export */
import React from 'react';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { BuilderSections } from '../../util/builder';
import RepresentPrivacyPolicy from '../../components/PrivacyPolicy/RepresentPrivacyPolicy';
import RepresentTermsOfService from '../../components/TermsOfService/RepresentTermsOfService';
import { Currency } from '../../types/apollo/generated/types.generated';

const representSizes = [
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'One Size',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '36',
  '38',
  'UK6 / EU40 / US7',
  'UK6.5 / EU40.5 / US7.5',
  'UK7 / EU41 / US8',
  'UK7.5 / EU41.5 / US8.5',
  'UK8 / EU42 / US9',
  'UK8.5 / EU42.5 / US9.5',
  'UK9 / EU43 / US10',
  'UK9.5 / EU43.5 / US10.5',
  'UK10 / EU44 / US11',
  'UK10.5 / EU44.5 / US11.5',
  'UK11 / EU45 / US12',
  'UK11.5 / EU45.5 / US12.5',
  'UK12 / EU46 / US13',
  'UK12.5 / EU46.5 / US13.5',
  'UK13 / EU47 / US14',
];

const representAdditionalPayoutSettings = {
  additionalShopifyGiftCardRequirements: {
    giftCardExpiresAfterDays: 365, // 1 year
  },
  creditCodeHelperText: 'Gift card expires 1 year after date of issue.',
};

export const representShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: representSizes }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '6e46ea194772449cbd3f1da78e6c563c',
      [BuilderSections.AboutPageHeroContent]: '4e927a0e68ed4db9a44e2481fce5cea4',
      [BuilderSections.ListingPageContent]: '71daf4724c9e4792b6f5abd6f8e031fa',
      [BuilderSections.ListingPageTitleAndVariantDetailsContent]:
        '1e55cf3ca8a94d3da4122f2c0c10cabb',
      [BuilderSections.ListingCardDetails]: 'b7b1c01217214c1386ffc091a90c583f',
      [BuilderSections.FooterContent]: 'c54aa4b1595b48dcb44d053da10fec5b',
      [BuilderSections.TopbarContent]: '2b581895a3ba4af8a77e764697a8538d',
      [BuilderSections.TopbarMobileContent]: '8c51773516f443769917a28c4229e796',
    },
  },
  loyaltyPointsConfig: {
    pdpIconId: 'represent_prestige',
  },
  customComponents: {
    privacyPolicyComponent: <RepresentPrivacyPolicy />,
    termsOfServiceComponent: <RepresentTermsOfService />,
  },
  returnShippingPrice: {
    price: 599,
    currency: Currency.Gbp,
  },
  additionalPayoutSettings: representAdditionalPayoutSettings,
};
