/* eslint-disable import/prefer-default-export */
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { BuilderSections } from '../../util/builder';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';

const proudpoppyClothingSizeOptions = [
  'OS',
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'XXXL',
  '6',
  '8',
  '10',
  '12',
  '14',
  '16',
  '18',
  '20',
  '22',
  '24',
  '26',
  '28',
  '30',
];

const categoryPercentages = {
  jumpsuits: 0.07,
  skirts: 0.065,
  dresses: 0.06,
  jackets: 0.055,
  cardigans: 0.05,
  pants: 0.05,
  jeans: 0.05,
  tops: 0.046,
  shorts: 0.04,
};

const tagPercentages = {
  'new-arrivals': 0.07,
  'short-dress': 0.066,
  'short-sleeve-dress': 0.066,
  dress: 0.066,
  Dresses: 0.066,
  curvedress: 0.064,
  summerdresses: 0.064,
  summerdress: 0.064,
  pants: 0.053,
  'best-sellers': 0.05,
  'Occasion:Bump Friendly': 0.041,
  'Occasion:Party': 0.04,
  'Occasion:Feeding Friendly': 0.04,
  'Occasion:Summer': 0.04,
  'Occasion:Work': 0.04,
  'Occasion:Vacation': 0.039,
  'Occasion:Spring': 0.039,
  'Occasion:Teacher Outfits': 0.039,
  'Occasion:Wedding Guest': 0.039,
  'Occasion:Casual': 0.039,
  top: 0.038,
  summertop: 0.037,
  tops: 0.037,
  curvetop: 0.036,
  spring: 0.014,
  generous: 0.014,
  knit: 0.014,
  breastfeeding: 0.014,
  gifting: 0.014,
  jumper: 0.013,
  dressy: 0.013,
  curve: 0.013,
  summervibes: 0.013,
  'pauls-party': 0.013,
  bump: 0.013,
  'store exclusive': 0.013,
  babybump: 0.013,
  curves: 0.013,
  normal: 0.013,
  bumpfriendly: 0.012,
  bumpf: 0.012,
  'Colour:Blue': 0.012,
  accessories: 0.012,
  bumplovers: 0.012,
  basic: 0.012,
  'Colour:Pink': 0.011,
  floral: 0.011,
  'Print:Floral': 0.011,
  summer: 0.011,
  'Colour:Green': 0.011,
  leopard: 0.011,
  boho: 0.011,
  midi: 0.011,
  feeding: 0.011,
  'long sleeve': 0.011,
  'Fabric:Rayon': 0.011,
  'mothers-day': 0.011,
  longsleeves: 0.011,
  letmetrybeforeyoubuy: 0.011,
  basics: 0.011,
  'Sleeve Length:Short': 0.011,
  'Fabric:Cotton': 0.011,
  'kasey rainbow': 0.011,
  'kasey-rainbow': 0.011,
  'Fabric:Polyester': 0.011,
  feedingfriendly: 0.011,
  'Colour:Multi': 0.011,
  pink: 0.011,
  'Fabric:Linen': 0.011,
  'Colour:Black': 0.011,
  'Length:Midi': 0.011,
  over$50: 0.011,
  'Sleeve Length:Mid Length': 0.011,
  'Print:Kasey Rainbow': 0.011,
  pocketss: 0.011,
  natalieangel: 0.011,
  'Length:Short': 0.01,
  lmtbyb: 0.01,
  'Length:Maxi': 0.01,
  'gifting-bundles': 0.01,
  'Fabric:Viscose': 0.01,
  shoes: 0.01,
  MAXI: 0.01,
  feedinglovers: 0.01,
  'winter-rug-up': 0.01,
  kasey: 0.01,
  'valentines-day': 0.01,
  'label-kasey-rainbow': 0.01,
  winter: 0.01,
  pocket: 0.01,
  black: 0.01,
  pockets: 0.01,
  '14-sale': -0.005,
  '12-sale': -0.005,
  '6-sale': -0.005,
  '10-sale': -0.005,
  '8-sale': -0.005,
  'summer-sale': -0.005,
  'pp-bday': -0.005,
  'No return': -0.005,
  eofy: -0.005,
  'Final Sale': -0.01,
};

const proudpoppyclothingAdditionalPayoutSettings = {
  additionalShopifyGiftCardRequirements: {
    giftCardExpiresAfterDays: 1095, // 3 years
  },
  creditCodeHelperText: 'Gift card expires 3 years after date of issue.',
};

export const proudpoppyclothingShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: proudpoppyClothingSizeOptions }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: 'f09e3351221d4bcaa121ba48fb977a7b',
    },
  },
  additionalPayoutSettings: proudpoppyclothingAdditionalPayoutSettings,
  resaleValueConfig: {
    categoryPercentages,
    tagPercentages,
  },
};
