/* eslint-disable import/prefer-default-export */
import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { Conditions, getConditionsFilterConfig } from '../filters/condition';
import { BuilderSections } from '../../util/builder';

const carilohaSizeOptions = [
  'XS',
  'S',
  'S/M',
  'M',
  'L',
  'L/XL',
  'XL',
  '2XL',
  '3XL',
  'Crib',
  'Twin',
  'Twin XL',
  'Standard',
  'Full',
  'Full/Queen',
  'Queen',
  'King',
  'King/Cal-King',
  'Cal-King',
  'Split-King',
  'One Size',
  'Hand Towel',
  'Bath Towel',
  'Bath Sheet',
];

const carilohaAdditionalPayoutSettings = {
  additionalCouponCodeRequirements: {
    minPurchase: 200,
    // appliesTo is required for Big Commerce Coupon Codes. Entity is what the coupon is applied to.
    // These values were pulled from existing Cariloha coupon codes
    appliesTo: { entity: 'categories', ids: [18, 24, 32, 33, 48, 51, 54, 114, 117] },
  },
  creditCodeHelperText:
    'Code can only be used on cariloha.com for purchases over $200. Limit one code per checkout.',
  tradeInHelperText:
    'Credit will be issued by Cariloha once item(s) are scanned in by USPS. Expect an email with your $50 credit within 24 hours of your items being scanned',
  tradeInCreditPayoutBundleAmount: 5000,
};

const carilohaConditions = [
  {
    key: Conditions.NewWithTags,
    label: 'New With Packaging',
    description:
      'Your item has never been used or worn and is still in the original packaging with hangtags.',
  },
  {
    key: Conditions.Excellent,
    label: 'Excellent Condition',
    description: 'Your item has only been lightly used and has been extremely well maintained.',
  },
  {
    key: Conditions.Good,
    label: 'Well Loved',
    description:
      'The item has been used frequently. It may have a small quirk like a button missing, or a loose thread.',
  },
];

export const carilohaShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig({ options: carilohaConditions }),
    getSizeFilterConfig({ sizes: carilohaSizeOptions }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '2d40278a501a48c48c54aac1522b66c3',
      [BuilderSections.AboutPageHeroContent]: 'ce922a9144224cf6957f65a0798b3b44',
      [BuilderSections.ListingCardDetails]: '9c9e08636e58445cb05aab0d4cb7e8fb',
      [BuilderSections.ListingPageTitleAndVariantDetailsContent]:
        'ad1780a90fec4a83935a9cc43e2163be',
      [BuilderSections.TopbarContent]: 'f3f11832c4c843b590c40af605be578c',
    },
  },
  additionalPayoutSettings: carilohaAdditionalPayoutSettings,
};
