import React, { FC } from 'react';
import TypographyWrapper, { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';

interface MainContentIfUsingLoyaltyPointsProps {
  formattedCreditPayout: string;
}

const MainContentIfUsingLoyaltyPoints: FC<MainContentIfUsingLoyaltyPointsProps> = ({
  formattedCreditPayout,
}) => (
  <>
    <TypographyWrapper
      variant="body1"
      weight={TypographyWeight.Bold}
      typographyOverrides={{ style: { display: 'inline', fontSize: 'inherit' } }}
    >
      {`Get ${formattedCreditPayout}% brand credit `}
    </TypographyWrapper>
    in loyalty points when your item sells.
  </>
);

interface MainContentIfUsingBrandCreditProps {
  formattedCreditPayout: string;
  formattedPromoCreditPayoutPercentage?: string;
}
const MainContentIfUsingBrandCredit: FC<MainContentIfUsingBrandCreditProps> = ({
  formattedCreditPayout,
  formattedPromoCreditPayoutPercentage,
}) => {
  const activeCreditPercentage = formattedPromoCreditPayoutPercentage ?? formattedCreditPayout;
  const inactiveCreditStrikethrough = formattedPromoCreditPayoutPercentage ? (
    <>
      {' '}
      <TypographyWrapper
        variant="body1"
        component="span"
        typographyOverrides={{
          style: {
            display: 'inline',
            fontSize: 'inherit',
            textDecoration: 'line-through',
          },
        }}
      >
        {formattedCreditPayout}%
      </TypographyWrapper>{' '}
    </>
  ) : null;

  return (
    <>
      Get {inactiveCreditStrikethrough}
      <TypographyWrapper
        variant="body1"
        component="span"
        weight={TypographyWeight.Bold}
        typographyOverrides={{ style: { display: 'inline', fontSize: 'inherit' } }}
      >
        {`${activeCreditPercentage}% `}
      </TypographyWrapper>
      brand credit for every item you sell.
    </>
  );
};

interface CreditMainContentProps {
  doesBrandUseLoyaltyPoints: boolean;
  formattedCreditPayout: string;
  formattedPromoCreditPayoutPercentage?: string;
}

const CreditMainContent: React.FC<CreditMainContentProps> = ({
  doesBrandUseLoyaltyPoints,
  formattedCreditPayout,
  formattedPromoCreditPayoutPercentage,
}) =>
  doesBrandUseLoyaltyPoints ? (
    <MainContentIfUsingLoyaltyPoints formattedCreditPayout={formattedCreditPayout} />
  ) : (
    <MainContentIfUsingBrandCredit
      formattedCreditPayout={formattedCreditPayout}
      formattedPromoCreditPayoutPercentage={formattedPromoCreditPayoutPercentage}
    />
  );

export default CreditMainContent;
