import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { useShopConfig } from '../../hooks/shopConfig';
import { defaultTreetStyles } from '../../shopConfig/config';
import TypographyWrapper, { TypographyFormat } from '../TypographyWrapper/TypographyWrapper';
import IconLinkTo from '../Icons/IconLinkTo/IconLinkTo';

const openMainSite = (mainSite: string) => {
  window.open(mainSite, '_blank');
};

const MainSiteBanner: FC = () => {
  const { shopName, mainSite, css: brandCss } = useShopConfig();
  const bannerColor = brandCss?.primaryButton?.backgroundColor || defaultTreetStyles.gray80;
  const textColor = brandCss?.primaryButton?.font?.color || 'white';
  if (!mainSite) {
    return null;
  }

  return (
    <Box
      bgcolor={bannerColor}
      p={1.5}
      onClick={() => openMainSite(mainSite)}
      style={{ cursor: 'pointer' }}
      display="flex"
      justifyContent="flex-start"
    >
      <TypographyWrapper
        variant="subtitle1"
        format={TypographyFormat.HoverUnderlined}
        typographyOverrides={{
          style: { color: textColor, marginRight: '8px', marginLeft: '20px' },
        }}
      >
        {shopName} Main Site
      </TypographyWrapper>
      <IconLinkTo color={textColor} />
    </Box>
  );
};

export default MainSiteBanner;
