/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';

const astraldesignsSizes = [
  'W 6',
  'W 6.5',
  'W 7',
  'W 7.5',
  'W 8',
  'W 8.5',
  'W 9',
  'W 9.5',
  'W 10',
  'W 10.5',
  'W 11',
  'M 8',
  'M 8.5',
  'M 9',
  'M 9.5',
  'M 10',
  'M 10.5',
  'M 11',
  'M 11.5',
  'M 12',
  'M 13',
  'M 14',
  'Small',
  'Medium',
  'Large',
  'S/M',
  'M/L',
  'L/XL',
];

export const astraldesignsShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: astraldesignsSizes }),
  ],
};
