// This file deals with Flex API which will create Stripe Custom Connect accounts
// from given bank_account tokens.
import config from '../shopConfig/config';
// eslint-disable-next-line import/no-cycle
import { updateProfile } from '../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { storableError } from '../util/errors';
import * as log from '../util/log';
import { handle } from '../util/helpers';
import {
  createStripeAccount as createStripeAccountApiRequest,
  createStripeAccountLink,
  retrieveStripeAccount as retrieveStripeAccountApiRequest,
  updateStripeAccount as updateStripeAccountApiRequest,
} from '../util/api';
import { apolloClient } from '../apollo';
import { CountryCode, UpdateUserDocument } from '../types/apollo/generated/types.generated';
import { formatToSharetribeStripeAccount } from '../util/stripe';

// ================ Action types ================ //

export const STRIPE_ACCOUNT_CREATE_REQUEST = 'app/stripe/STRIPE_ACCOUNT_CREATE_REQUEST';
export const STRIPE_ACCOUNT_CREATE_SUCCESS = 'app/stripe/STRIPE_ACCOUNT_CREATE_SUCCESS';
export const STRIPE_ACCOUNT_CREATE_ERROR = 'app/stripe/STRIPE_ACCOUNT_CREATE_ERROR';

export const STRIPE_ACCOUNT_UPDATE_REQUEST = 'app/stripe/STRIPE_ACCOUNT_UPDATE_REQUEST';
export const STRIPE_ACCOUNT_UPDATE_SUCCESS = 'app/stripe/STRIPE_ACCOUNT_UPDATE_SUCCESS';
export const STRIPE_ACCOUNT_UPDATE_ERROR = 'app/stripe/STRIPE_ACCOUNT_UPDATE_ERROR';

export const STRIPE_ACCOUNT_FETCH_REQUEST = 'app/stripe/STRIPE_ACCOUNT_FETCH_REQUEST';
export const STRIPE_ACCOUNT_FETCH_SUCCESS = 'app/stripe/STRIPE_ACCOUNT_FETCH_SUCCESS';
export const STRIPE_ACCOUNT_FETCH_ERROR = 'app/stripe/STRIPE_ACCOUNT_FETCH_ERROR';

export const STRIPE_ACCOUNT_CLEAR_ERROR = 'app/stripe/STRIPE_ACCOUNT_CLEAR_ERROR';

export const GET_ACCOUNT_LINK_REQUEST = 'app/stripeConnectAccount.duck.js/GET_ACCOUNT_LINK_REQUEST';
export const GET_ACCOUNT_LINK_SUCCESS = 'app/stripeConnectAccount.duck.js/GET_ACCOUNT_LINK_SUCCESS';
export const GET_ACCOUNT_LINK_ERROR = 'app/stripeConnectAccount.duck.js/GET_ACCOUNT_LINK_ERROR';

// ================ Reducer ================ //

const initialState = {
  createStripeAccountInProgress: false,
  createStripeAccountError: null,
  updateStripeAccountInProgress: false,
  updateStripeAccountError: null,
  fetchStripeAccountInProgress: false,
  fetchStripeAccountError: null,
  getAccountLinkInProgress: false,
  getAccountLinkError: null,
  stripeAccount: null,
  stripeAccountFetched: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case STRIPE_ACCOUNT_CREATE_REQUEST:
      return { ...state, createStripeAccountError: null, createStripeAccountInProgress: true };
    case STRIPE_ACCOUNT_CREATE_SUCCESS:
      return {
        ...state,
        createStripeAccountInProgress: false,
        stripeAccount: payload,
        stripeAccountFetched: true,
      };
    case STRIPE_ACCOUNT_CREATE_ERROR:
      console.error(payload);
      return { ...state, createStripeAccountError: payload, createStripeAccountInProgress: false };

    case STRIPE_ACCOUNT_UPDATE_REQUEST:
      return { ...state, updateStripeAccountError: null, updateStripeAccountInProgress: true };
    case STRIPE_ACCOUNT_UPDATE_SUCCESS:
      return {
        ...state,
        updateStripeAccountInProgress: false,
        stripeAccount: payload,
        stripeAccountFetched: true,
      };
    case STRIPE_ACCOUNT_UPDATE_ERROR:
      console.error(payload);
      return { ...state, updateStripeAccountError: payload, createStripeAccountInProgress: false };

    case STRIPE_ACCOUNT_FETCH_REQUEST:
      return { ...state, fetchStripeAccountError: null, fetchStripeAccountInProgress: true };
    case STRIPE_ACCOUNT_FETCH_SUCCESS:
      return {
        ...state,
        fetchStripeAccountInProgress: false,
        stripeAccount: payload,
        stripeAccountFetched: true,
      };
    case STRIPE_ACCOUNT_FETCH_ERROR:
      console.error(payload);
      return { ...state, fetchStripeAccountError: payload, fetchStripeAccountInProgress: false };

    case STRIPE_ACCOUNT_CLEAR_ERROR:
      return { ...initialState };

    case GET_ACCOUNT_LINK_REQUEST:
      return { ...state, getAccountLinkError: null, getAccountLinkInProgress: true };
    case GET_ACCOUNT_LINK_ERROR:
      console.error(payload);
      return { ...state, getAccountLinkInProgress: false, getAccountLinkError: payload };
    case GET_ACCOUNT_LINK_SUCCESS:
      return { ...state, getAccountLinkInProgress: false };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const stripeAccountCreateRequest = () => ({ type: STRIPE_ACCOUNT_CREATE_REQUEST });

export const stripeAccountCreateSuccess = (stripeAccount) => ({
  type: STRIPE_ACCOUNT_CREATE_SUCCESS,
  payload: stripeAccount,
});

export const stripeAccountCreateError = (e) => ({
  type: STRIPE_ACCOUNT_CREATE_ERROR,
  payload: e,
  error: true,
});

export const stripeAccountUpdateRequest = () => ({ type: STRIPE_ACCOUNT_UPDATE_REQUEST });

export const stripeAccountUpdateSuccess = (stripeAccount) => ({
  type: STRIPE_ACCOUNT_UPDATE_SUCCESS,
  payload: stripeAccount,
});

export const stripeAccountUpdateError = (e) => ({
  type: STRIPE_ACCOUNT_UPDATE_ERROR,
  payload: e,
  error: true,
});

export const stripeAccountFetchRequest = () => ({ type: STRIPE_ACCOUNT_FETCH_REQUEST });

export const stripeAccountFetchSuccess = (stripeAccount) => ({
  type: STRIPE_ACCOUNT_FETCH_SUCCESS,
  payload: stripeAccount,
});

export const stripeAccountFetchError = (e) => ({
  type: STRIPE_ACCOUNT_FETCH_ERROR,
  payload: e,
  error: true,
});

export const stripeAccountClearError = () => ({
  type: STRIPE_ACCOUNT_CLEAR_ERROR,
});

export const getAccountLinkRequest = () => ({
  type: GET_ACCOUNT_LINK_REQUEST,
});
export const getAccountLinkError = (e) => ({
  type: GET_ACCOUNT_LINK_ERROR,
  payload: e,
  error: true,
});
export const getAccountLinkSuccess = () => ({
  type: GET_ACCOUNT_LINK_SUCCESS,
});

// ================ Thunks ================ //

const handleError = (callback, error, message) => {
  const e = storableError(error);
  const stripeMessage =
    e.apiErrors && e.apiErrors.length > 0 && e.apiErrors[0].meta
      ? e.apiErrors[0].meta.stripeMessage
      : null;
  callback(e);
  log.error(error, message, { stripeMessage });
  throw e;
};

const isNonUSStripeAccount = (stripeAccount) =>
  stripeAccount?.attributes.stripeAccountData &&
  stripeAccount.attributes.stripeAccountData.country !== CountryCode.Us;

const createUSStripeAccount = async (dispatch, sdk, stripe, params) => {
  const { country, accountType, bankAccountToken, businessProfileMCC, businessProfileURL } = params;
  const accountInfo = {
    business_type: accountType,
    tos_shown_and_accepted: true,
  };

  dispatch(stripeAccountCreateRequest());

  const [createStripeTokenResponse, createStripeTokenError] = await handle(
    stripe.createToken('account', accountInfo)
  );

  if (createStripeTokenError) {
    handleError(
      (e) => dispatch(stripeAccountCreateError(e)),
      createStripeTokenError,
      'create-stripe-token-failed'
    );
  }

  const accountToken = createStripeTokenResponse.token.id;
  // Capabilities are a collection of settings that can be requested for each
  // provider. What Capabilities are required determines what information Stripe
  // requires to be collected from the providers. You can read more from here:
  // https://stripe.com/docs/connect/capabilities-overview In Flex both
  // 'card_payments' and 'transfers' are required. However, we changed the
  // payout flow to only require transfers since money flows from the customer
  // to the platform (Treet) then to provider.
  const requestedCapabilities = ['transfers'];

  const [createStripeAccountResponse, createStripeAccountError] = await handle(
    sdk.stripeAccount.create(
      {
        country,
        accountToken,
        bankAccountToken,
        requestedCapabilities,
        businessProfileMCC,
        businessProfileURL,
      },
      { expand: true }
    )
  );

  if (createStripeAccountError) {
    handleError(
      (e) => dispatch(stripeAccountCreateError(e)),
      createStripeAccountError,
      'create-us-stripe-account-failed'
    );
  }

  return createStripeAccountResponse.data.data;
};

const createNonUSStripeAccount = async (dispatch, params) => {
  const {
    country,
    bankAccountToken,
    accountType,
    businessProfileMCC,
    businessProfileURL,
    email,
    currentUserId,
  } = params;

  const [createStripeAccountResponse, createStripeAccountError] = await handle(
    createStripeAccountApiRequest({
      type: 'custom',
      country,
      capabilities: { transfers: { requested: true } },
      accountType,
      bankAccountToken,
      businessProfileMCC,
      businessProfileURL,
      email,
      tosAcceptance: { service_agreement: 'recipient' },
      currentUserId,
    })
  );

  if (createStripeAccountError) {
    handleError(
      (e) => dispatch(stripeAccountCreateError(e)),
      createStripeAccountError,
      'create-non-us-stripe-account-failed'
    );
  }

  const [, postgresUpdateUserError] = await handle(
    apolloClient.mutate({
      mutation: UpdateUserDocument,
      variables: {
        input: {
          email,
          stripeAccountId: createStripeAccountResponse.id,
        },
      },
    })
  );

  if (postgresUpdateUserError) {
    // Log error to sentry only without disrupting the user flow.
    // (TODO|TREET-3578): Once Users data is fully migrated off of Sharetribe, start throwing
    // error if updating PG User fails.
    log.error(postgresUpdateUserError, 'postgres-update-user-stripe-account-failed', {
      email,
      createStripeAccountResponse,
    });
  }

  // Mimic Sharetribe response
  return formatToSharetribeStripeAccount(createStripeAccountResponse);
};

export const createStripeAccount = (params) => async (dispatch, getState, sdk) => {
  if (typeof window === 'undefined' || !window.Stripe) {
    throw new Error('Stripe must be loaded for submitting PayoutPreferences');
  }

  const { currentUser } = getState().user;
  const { country } = params;
  const stripe = window.Stripe(config.stripe.publishableKey);

  let stripeAccount;
  if (country === CountryCode.Us) {
    stripeAccount = await createUSStripeAccount(dispatch, sdk, stripe, params);
  } else if (currentUser) {
    stripeAccount = await createNonUSStripeAccount(dispatch, {
      ...params,
      email: currentUser.attributes.email,
      currentUserId: currentUser.id.uuid,
    });
  }

  dispatch(
    updateProfile({ protectedData: { stripeAccountId: stripeAccount.attributes.stripeAccountId } })
  );
  dispatch(stripeAccountCreateSuccess(stripeAccount));
  return stripeAccount;
};

const updateUSStripeAccount = async (dispatch, sdk, bankAccountToken) => {
  const [updateStripeAccountResponse, updateStripeAccountError] = await handle(
    sdk.stripeAccount.update(
      { bankAccountToken, requestedCapabilities: ['transfers'] },
      { expand: true }
    )
  );

  if (updateStripeAccountError) {
    handleError(
      (e) => dispatch(stripeAccountUpdateError(e)),
      updateStripeAccountError,
      'update-us-stripe-account-failed'
    );
  }

  return updateStripeAccountResponse.data.data;
};

const updateNonUSStripeAccount = async (dispatch, accountId, currentUserId, updateParams) => {
  const [updateStripeAccountResponse, updateStripeAccountError] = await handle(
    updateStripeAccountApiRequest({ accountId, currentUserId, updateParams })
  );

  if (updateStripeAccountError) {
    handleError(
      (e) => dispatch(stripeAccountUpdateError(e)),
      updateStripeAccountError,
      'update-non-us-stripe-account-failed'
    );
  }

  // Mimic Sharetribe response
  return formatToSharetribeStripeAccount(updateStripeAccountResponse);
};

// This function is used for updating the bank account token
// but could be expanded to other information as well.
//
// If the Stripe account has been created with account token,
// you need to use account token also to update the account.
// By default the account token will not be used.
// See API reference for more information:
// https://www.sharetribe.com/api-reference/?javascript#update-stripe-account
export const updateStripeAccount = (params) => async (dispatch, getState, sdk) => {
  const { bankAccountToken } = params;

  const { currentUser } = getState().user;
  const { stripeAccount } = getState().stripeConnectAccount;

  dispatch(stripeAccountUpdateRequest());

  let updatedStripeAccount;
  if (currentUser && isNonUSStripeAccount(stripeAccount)) {
    updatedStripeAccount = await updateNonUSStripeAccount(
      dispatch,
      currentUser.attributes.profile.protectedData?.stripeAccountId,
      currentUser.id.uuid,
      { external_account: bankAccountToken }
    );
  } else {
    updatedStripeAccount = await updateUSStripeAccount(dispatch, sdk, bankAccountToken);
  }

  const { stripeAccountId } = updatedStripeAccount.attributes;
  dispatch(updateProfile({ protectedData: { stripeAccountId } }));
  dispatch(stripeAccountUpdateSuccess(updatedStripeAccount));
  return updatedStripeAccount;
};

const fetchUSStripeAccount = async (dispatch, sdk) => {
  const [fetchStripeAccountResponse, fetchStripeAccountError] = await handle(
    sdk.stripeAccount.fetch()
  );

  if (fetchStripeAccountError) {
    handleError(
      (e) => dispatch(stripeAccountFetchError(e)),
      fetchStripeAccountError,
      'fetch-us-stripe-account-failed'
    );
  }

  return fetchStripeAccountResponse.data.data;
};

const fetchNonUSStripeAccount = async (dispatch, accountId, currentUserId) => {
  const [fetchStripeAccountResponse, fetchStripeAccountError] = await handle(
    retrieveStripeAccountApiRequest({ accountId, currentUserId })
  );

  if (fetchStripeAccountError) {
    handleError(
      (e) => dispatch(stripeAccountFetchError(e)),
      fetchStripeAccountError,
      'fetch-non-us-stripe-account-failed'
    );
  }

  // Mimic Sharetribe response
  return formatToSharetribeStripeAccount(fetchStripeAccountResponse);
};

export const fetchStripeAccount = () => async (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  const { stripeAccount } = getState().stripeConnectAccount;

  dispatch(stripeAccountFetchRequest());

  let retrievedStripeAccount;
  if (currentUser && isNonUSStripeAccount(stripeAccount)) {
    const stripeAccountId = currentUser.attributes.profile.protectedData?.stripeAccountId;
    retrievedStripeAccount = await fetchNonUSStripeAccount(
      dispatch,
      stripeAccountId,
      currentUser.id.uuid
    );
  } else {
    retrievedStripeAccount = await fetchUSStripeAccount(dispatch, sdk);
  }

  dispatch(stripeAccountFetchSuccess(retrievedStripeAccount));
  return retrievedStripeAccount;
};

const getUSStripeConnectAccountLink = async (dispatch, sdk, params) => {
  const { failureURL, successURL, type } = params;

  const [createStripeAccountLinkResponse, createStripeAccountLinkError] = await handle(
    sdk.stripeAccountLinks.create({
      failureURL,
      successURL,
      type,
      collect: 'eventually_due',
      // NOTE: collect is deprecated, use collection_options instead after
      // we upgrade Sharetribe API.
      // collectionOptions: {
      //   fields: 'eventually_due',
      //   futureRequirements: 'include',
      // },
    })
  );

  if (createStripeAccountLinkError) {
    handleError(
      (e) => dispatch(getAccountLinkError(e)),
      createStripeAccountLinkError,
      'get-us-stripe-account-link-failed'
    );
  }

  // Return the account link
  return createStripeAccountLinkResponse.data.data.attributes.url;
};

const getNonUSStripeConnectAccountLink = async (dispatch, accountId, currentUserId, params) => {
  const { failureURL, successURL, type } = params;

  const accountLinkType =
    // eslint-disable-next-line no-nested-ternary
    type === 'custom_account_verification'
      ? 'account_onboarding'
      : type === 'custom_account_update'
      ? 'account_update'
      : type;

  const [createStripeAccountLinkResponse, createStripeAccountLinkError] = await handle(
    createStripeAccountLink({
      currentUserId,
      accountId,
      failureURL,
      successURL,
      type: accountLinkType,
    })
  );

  if (createStripeAccountLinkError) {
    handleError(
      (e) => dispatch(getAccountLinkError(e)),
      createStripeAccountLinkError,
      'get-non-us-stripe-account-link-failed'
    );
  }

  // Return the account link
  return createStripeAccountLinkResponse.url;
};

export const getStripeConnectAccountLink = (params) => async (dispatch, getState, sdk) => {
  dispatch(getAccountLinkRequest());

  const { currentUser } = getState().user;
  const { stripeAccount } = getState().stripeConnectAccount;

  let stripeConnectAccountLink;
  if (currentUser && isNonUSStripeAccount(stripeAccount)) {
    stripeConnectAccountLink = await getNonUSStripeConnectAccountLink(
      dispatch,
      currentUser.attributes.profile.protectedData?.stripeAccountId,
      currentUser.id.uuid,
      params
    );
  } else {
    stripeConnectAccountLink = await getUSStripeConnectAccountLink(dispatch, sdk, params);
  }

  return stripeConnectAccountLink;
};
