import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { IconAdmin, IconAdminFilled, NamedLink } from '..';
import { useIconColor } from '../../hooks/useIconColor';
import css from './AdminButton.module.css';

interface AdminButtonProps {
  isSelected: boolean;
  className?: string;
  iconClassName?: string;
  params?: Record<any, any>;
}

const AdminButton: FC<AdminButtonProps> = (props: AdminButtonProps) => {
  const { className, iconClassName, isSelected, params } = props;

  const classes = classNames(css.root, className);

  let icon;
  if (isSelected) {
    icon = <IconAdminFilled className={iconClassName} />;
  } else {
    icon = <IconAdmin className={iconClassName} />;
  }

  return (
    <NamedLink className={classes} name="AdminPage" params={params} title="Admin Page">
      {icon}
    </NamedLink>
  );
};

export default AdminButton;
