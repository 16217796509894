import Qty from 'js-quantities';
import { OwnListingWithImagesAndShopifyProductVariant } from '../types/sharetribe/listing';
import { ShippoAddress } from '../types/shippo/address';
import { CountryCodeAndName } from './countryCodes';
import { CountryCode } from '../types/apollo/generated/types.generated';
import { IShippingConfig } from '../types/contentful/types.generated';

const WEIGHT_UNIT_CONVERSIONS: { [type: string]: string } = {
  // Shopify weight strings
  POUNDS: 'lb',
  OUNCES: 'oz',
  KILOGRAMS: 'kg',
  GRAMS: 'g',
  // Prisma weight strings
  OZ: 'oz',
  G: 'g',
};

export const LB_WEIGHT_UNIT = 'lb';
export const KG_WEIGHT_UNIT = 'kg';

export enum ShipDeadline {
  BrandDirect = 14, // in days
  TradeIn = 14,
  Default = 7,
}

export enum ShippingStatus {
  InTransit = 'IN_TRANSIT',
  Delivered = 'DELIVERED',
}

export const DEFAULT_TRADE_IN_MAX_WEIGHT_CONFIG = { [KG_WEIGHT_UNIT]: 6.5, [LB_WEIGHT_UNIT]: 15 };
export const DEFAULT_MARKETPLACE_MAX_WEIGHT_CONFIG = { [KG_WEIGHT_UNIT]: 2.5, [LB_WEIGHT_UNIT]: 5 };

export const RETAIL_REWORKS_ADDRESS: ShippoAddress = {
  name: 'Retail Reworks',
  street1: '9282 Baythorne Drive',
  street2: '',
  city: 'Houston',
  state: 'TX',
  country: 'US',
  zip: '77041',
};

/**
 * Rounds a number to two decimal places. Will not necessarily maintain
 * those decimal places when converted to a number (it will drop any trailing zeroes).
 */
const roundAndTruncate = (num: number) => Number((Math.round(num * 100) / 100).toFixed(2));

interface LocalizedWeightType {
  weight: number;
  unit: string;
  string: string;
}

// Formatting to ensure standardized display.
const formatAsLocalizedWeightType = (qty: Qty): LocalizedWeightType => ({
  weight: roundAndTruncate(qty.scalar),
  unit: qty.units(),
  string: qty.toPrec(0.01).toString(),
});

// Sanitize to avoid any unrecognized Qty error.
const sanitizeUnit = (unit: string) => WEIGHT_UNIT_CONVERSIONS[unit] || unit.toLowerCase();

/**
 * Converts any weight from one unit to another. Standardizes units & returns formatted type.
 *
 */
export const getConvertedWeight = (
  weight: number,
  originalUnit: string,
  convertedUnit: string
): LocalizedWeightType => {
  const convertedWeight = Qty(weight, sanitizeUnit(originalUnit)).to(sanitizeUnit(convertedUnit));
  return formatAsLocalizedWeightType(convertedWeight);
};

/**
 * Returns the total weight for the given listings in the given unit.
 */
export const getConvertedCombinedWeight = (
  listings: OwnListingWithImagesAndShopifyProductVariant[],
  convertedUnit: string
): number => {
  const listingWeights = listings.map((listing) => {
    const { shopifyProductVariant } = listing.attributes.publicData;

    if (!shopifyProductVariant) {
      return new Qty(0, sanitizeUnit(convertedUnit));
    }
    const { weight, weightUnit } = shopifyProductVariant;
    return new Qty(weight, sanitizeUnit(weightUnit));
  });

  const initialEmptyWeight = new Qty(0, sanitizeUnit(convertedUnit));

  const listingsWeightTotal = listingWeights.reduce(
    (sum, curr) => sum.add(curr),
    initialEmptyWeight
  );

  return roundAndTruncate(listingsWeightTotal.scalar);
};

/**
 * Returns the maximum allowed weight for bundle, based on bundle type & localized weight unit.
 */
export const getLocalizedMaxWeight = (
  isTradeInBundle: boolean,
  localizedWeightUnit: 'kg' | 'lb',
  shippingConfig?: IShippingConfig
): LocalizedWeightType => {
  let weight;
  if (isTradeInBundle) {
    weight =
      shippingConfig?.tradeInMaxWeight || DEFAULT_TRADE_IN_MAX_WEIGHT_CONFIG[localizedWeightUnit];
  } else {
    weight =
      shippingConfig?.marketplaceMaxWeight ||
      DEFAULT_MARKETPLACE_MAX_WEIGHT_CONFIG[localizedWeightUnit];
  }

  const weightQty = new Qty(weight, localizedWeightUnit);

  return formatAsLocalizedWeightType(weightQty);
};

export const getAllowedShipToCountryCodes = (
  isListingBrandDirect: boolean,
  isShippingFromUS: boolean,
  allowedShipToLocations: CountryCodeAndName[]
): CountryCodeAndName[] => {
  // Filter out CA if it's a P2P listing shipping from US.
  if (isShippingFromUS && !isListingBrandDirect) {
    return allowedShipToLocations.filter(
      (countryCodeAndName) => countryCodeAndName.code !== CountryCode.Ca
    );
  }
  return allowedShipToLocations;
};
