import { FindItemMethod } from '../types/sharetribe/listing';
import { useShopConfig } from './shopConfig';
import { useCurrentUserPermissions } from './useUserPermissions';

interface AllowedFindItemMethods {
  shouldAllowSearch: boolean;
  shouldAllowCannotFind: boolean;
  shouldAllowOrderHistory: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const useAllowedFindItemMethods = (): AllowedFindItemMethods => {
  const shopConfig = useShopConfig();
  const { isLoggedInAsLimitedUser } = useCurrentUserPermissions();
  const { listingFlowConfig } = shopConfig;
  const { allowedFindItemMethods } = listingFlowConfig;
  const shouldAllowSearch =
    allowedFindItemMethods.includes(FindItemMethod.Manual) || isLoggedInAsLimitedUser;
  const shouldAllowCannotFind = allowedFindItemMethods.includes(FindItemMethod.CannotFind);
  const shouldAllowOrderHistory =
    allowedFindItemMethods.includes(FindItemMethod.EmailAccount) ||
    allowedFindItemMethods.includes(FindItemMethod.OrderNumber);
  return { shouldAllowSearch, shouldAllowCannotFind, shouldAllowOrderHistory };
};
