import isNil from 'lodash/isNil';
import { compact, debounce, isArray, isEmpty, mergeWith, MergeWithCustomizer } from 'lodash';
import LZString from 'lz-string';
import * as closestMatch from 'closest-match';
import { v4 as uuidv4 } from 'uuid';
import { PricingRestriction, TradeInPriceRuleTarget } from '../../types/shopConfig/shopConfigV2';
import {
  Product,
  ShopifyProductVariant as ShopifyProductVariantType,
} from '../../types/shopify/product';
import {
  getOriginalShopifyProductPrice,
  ListingFlowFindItemMethod,
  OriginalPriceSource,
} from '../../util/listings/listing';
import { convertMoneyToNumber, convertNumberToMoney } from '../../util/currency';
import { calculateMinimumPrice, getRecommendedPrice } from '../../util/pricing';
import { Conditions } from '../../shopConfig/filters/condition';
import { FindItemMethod, OwnListing, OwnListingWithImages } from '../../types/sharetribe/listing';
import { Currency } from '../../types/apollo/generated/types.generated';
import { Feature, isFeatureEnabled } from '../../util/featureFlags';
import { Money as MoneyType } from '../../types/sharetribe/money';
import { getUrlHash } from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ITradeInPriceRule } from '../../types/contentful/types.generated';
import { getDraftListing, updateDraftListing } from '../../util/api';
import { handle } from '../../util/helpers';

const { Money } = sdkTypes;

// 3 seconds
const DEBOUNCE_WAIT_TIME = 3000;

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
export const DRAFT_ID = '00000000-0000-0000-0000-000000000000';
export const DRAFT_SLUG = 'draft';

export const IS_FROM_QR_QUERY_PARAM = 'from-qr';

export enum ListingPageFormIds {
  Search = 'search',
  Details = 'details',
}

export const mergeCustomizer: MergeWithCustomizer = (currentObj: any, newObj: any) => {
  // Default _.merge behavior treats arrays like objects
  // (['a','b'] -> { 0: 'a', 1: 'b' }) during a deep merge.
  // Override this behavior to treat arrays like a primitive data type,
  // so the entire array is replaced with the new value.
  if (isArray(currentObj)) {
    return newObj;
  }

  // Returning undefined falls back to the default
  // _.merge function's return value.
  return undefined;
};

export enum ListingPageParamTab {
  Search = 'search',
  OrderNumber = 'order-number',
  CannotFind = 'cannot-find',
  Details = 'details',
  ListAnyBrand = 'list-any-brand',
}

export const FIND_ITEM_METHOD_TO_TAB = {
  [FindItemMethod.Search]: ListingPageParamTab.Search,
  [FindItemMethod.EmailAccount]: ListingPageParamTab.Search,
  [FindItemMethod.AllProducts]: ListingPageParamTab.Search,
  [FindItemMethod.Upload]: ListingPageParamTab.Search,
  [FindItemMethod.ApiUpload]: ListingPageParamTab.Search,
  [FindItemMethod.TradeInUpload]: ListingPageParamTab.Search,
  [FindItemMethod.RelistFromTreet]: ListingPageParamTab.Search,
  [FindItemMethod.RelistAsDuplicate]: ListingPageParamTab.Search,
  [FindItemMethod.Manual]: ListingPageParamTab.Search,
  [FindItemMethod.OrderNumber]: ListingPageParamTab.OrderNumber,
  [FindItemMethod.CannotFind]: ListingPageParamTab.CannotFind,
  [FindItemMethod.ListAnyBrand]: ListingPageParamTab.ListAnyBrand,
};

export const shouldDisableTradeIn = (
  tradeInAllowedFindItemMethods?: ListingFlowFindItemMethod[],
  findItemMethod?: FindItemMethod | ListingFlowFindItemMethod
) => {
  // If it's not a user selected ListingFlowFindItemMethod, the item type
  // should have been programmatically set to MARKETPLACE.
  const isAutoSetToMarketplace = !Object.values(ListingFlowFindItemMethod).includes(
    findItemMethod as any
  );
  const isDisabledForTradeIn =
    !isNil(tradeInAllowedFindItemMethods) &&
    !tradeInAllowedFindItemMethods?.includes(findItemMethod as ListingFlowFindItemMethod);
  return !!findItemMethod && (isAutoSetToMarketplace || isDisabledForTradeIn);
};

export const getRecommendedMinPriceForPriceDrop = (price?: MoneyType) => {
  if (!price) {
    return undefined;
  }
  return convertNumberToMoney((price.amount * 80) / 10000, price.currency);
};

interface GetRecommendedPriceForConditionParams {
  price: MoneyType;
  condition: Conditions;
  conditionToRecommendedDiscount: { [condition in Conditions]: number };
  pricingRestrictions: PricingRestriction[];
  tags: string[] | null;
  category?: string;
  resaleValueConfig?: { [key: string]: string };
}

export const getRecommendedPriceForCondition = ({
  price,
  condition,
  conditionToRecommendedDiscount,
  pricingRestrictions,
  tags,
  category,
  resaleValueConfig,
}: GetRecommendedPriceForConditionParams): number => {
  const priceValue = price && convertMoneyToNumber(price);
  const calculatedMinimumPrice = calculateMinimumPrice(priceValue, pricingRestrictions, tags);
  const recommendedPrice = Math.max(
    getRecommendedPrice({
      price: priceValue,
      condition,
      conditionToRecommendedDiscount,
      category,
      productTags: tags,
      resaleValueConfig,
    }),
    calculatedMinimumPrice / 100
  );

  return recommendedPrice;
};

interface GetTradeInCreditPriceParams {
  treetId: string;
  shopifyProduct?: Product;
  shopifyProductVariant?: ShopifyProductVariantType | null;
  currency?: string;
  isPaidOutOnBundle?: boolean;
  condition?: Conditions;
  conditionToRecommendedDiscount?: { [condition in Conditions]: number };
  pricingRestrictions?: PricingRestriction[];
  tags?: string[] | null;
  category?: string;
  tradeInPriceRules?: ITradeInPriceRule[];
  originalPriceSources?: OriginalPriceSource[];
  findItemMethod?: FindItemMethod;
}

interface ApplyTradeInCreditPriceRulesParams {
  shopifyProduct?: Product;
  shopifyProductVariant?: ShopifyProductVariantType | null;
  currency?: string;
  condition?: Conditions;
  category?: string;
  tradeInPriceRules: ITradeInPriceRule[];
  originalPriceSources?: OriginalPriceSource[];
  findItemMethod?: FindItemMethod;
}

const calculateTradeInPrice = (
  tradeInPriceRule: ITradeInPriceRule,
  shopifyProduct?: Product,
  shopifyProductVariant?: ShopifyProductVariantType | null,
  currency?: string,
  originalPriceSources?: OriginalPriceSource[]
) => {
  const { fixedAmount, percentOfOriginalPrice, percentOfCustomShopifyMetafield } =
    tradeInPriceRule || {};

  if (fixedAmount) {
    return convertNumberToMoney(fixedAmount / 100, currency);
  }

  if (percentOfOriginalPrice) {
    const originalPrice = getOriginalShopifyProductPrice(
      currency as Currency,
      shopifyProduct,
      shopifyProductVariant,
      originalPriceSources
    );

    if (!isNil(percentOfOriginalPrice) && !isNil(originalPrice))
      return convertNumberToMoney(originalPrice.amount * percentOfOriginalPrice, currency);
  }

  if (percentOfCustomShopifyMetafield) {
    const tradeInPrice = shopifyProduct?.tradeInPrice?.value;
    if (!isNil(tradeInPrice))
      return convertNumberToMoney(Number(tradeInPrice) * percentOfCustomShopifyMetafield, currency);
  }

  return null;
};

export const applyTradeInCreditPriceRules = (params: ApplyTradeInCreditPriceRulesParams) => {
  const {
    tradeInPriceRules,
    shopifyProduct,
    shopifyProductVariant,
    currency,
    condition,
    category,
    originalPriceSources,
    findItemMethod,
  } = params;
  if (!currency) return undefined;

  // Price rules will be applied in the order of the array.
  for (let i = 0; i < tradeInPriceRules.length; i++) {
    const tradeInPriceRule = tradeInPriceRules[i];
    const { target, targetKey } = tradeInPriceRule;

    const isBundlePriceRule = target === TradeInPriceRuleTarget.Bundle;
    if (isBundlePriceRule) {
      // Do not set an individual listing trade-in price since it will be paid out on the
      // bundle level.
      const LISTING_PRICE_FLAT_RATE = 0;
      return convertNumberToMoney(Number(LISTING_PRICE_FLAT_RATE), currency);
    }

    const isValidItemPriceRule =
      target === TradeInPriceRuleTarget.Item ||
      (target === TradeInPriceRuleTarget.ItemWithCondition && targetKey === condition) ||
      (target === TradeInPriceRuleTarget.ItemWithCategory && targetKey === category) ||
      (target === TradeInPriceRuleTarget.ItemWithFindItemMethod && targetKey === findItemMethod);

    if (isValidItemPriceRule)
      return calculateTradeInPrice(
        tradeInPriceRule,
        shopifyProduct,
        shopifyProductVariant,
        currency,
        originalPriceSources
      );
  }
  return null;
};

// Exported to nodeExtractor.
export const getTradeInCreditPrice = (params: GetTradeInCreditPriceParams) => {
  const {
    treetId,
    shopifyProduct,
    shopifyProductVariant,
    currency,
    isPaidOutOnBundle,
    condition,
    conditionToRecommendedDiscount,
    pricingRestrictions = [],
    tags = [],
    category,
    tradeInPriceRules,
    originalPriceSources,
    findItemMethod,
  } = params;
  if (!currency) return undefined;

  if (!isEmpty(tradeInPriceRules)) {
    return applyTradeInCreditPriceRules({
      shopifyProduct,
      shopifyProductVariant,
      currency,
      condition,
      category,
      tradeInPriceRules: tradeInPriceRules!,
      originalPriceSources,
      findItemMethod,
    });
  }

  // TODO (TREET-5080) Migrate rest of trade-in price cases into contentful rules.
  if (isPaidOutOnBundle) {
    const LISTING_PRICE_FLAT_RATE = 0;
    return convertNumberToMoney(Number(LISTING_PRICE_FLAT_RATE), currency);
  }
  switch (treetId) {
    case 'doen': {
      const tradeInPrice = shopifyProduct?.tradeInPrice?.value;
      if (!isNil(tradeInPrice)) return convertNumberToMoney(Number(tradeInPrice) * 0.5, currency);

      const originalPrice = getOriginalShopifyProductPrice(
        currency as Currency,
        shopifyProduct,
        shopifyProductVariant,
        originalPriceSources
      );
      if (!isNil(originalPrice))
        return convertNumberToMoney(originalPrice.amount * 0.3, originalPrice.currencyCode);

      return undefined;
    }
    case 'mignonnegavigan': {
      const originalPrice = getOriginalShopifyProductPrice(
        currency as Currency,
        shopifyProduct,
        shopifyProductVariant,
        originalPriceSources
      );
      const isValidForRecommendedPrice = condition && conditionToRecommendedDiscount;
      if (!isNil(originalPrice) && isValidForRecommendedPrice) {
        const recommendedPrice = getRecommendedPriceForCondition({
          price: convertNumberToMoney(originalPrice.amount, originalPrice.currencyCode),
          condition: condition!,
          conditionToRecommendedDiscount: conditionToRecommendedDiscount!,
          pricingRestrictions,
          tags,
        });
        return convertNumberToMoney(recommendedPrice, currency);
      }
      if (isNil(originalPrice) && condition) {
        return convertNumberToMoney(Number(60), currency); // $60
      }
      return undefined;
    }
    default: {
      // Keep for demos. Default trade-in price to 50% of recommended selling price on Treet, or $10.
      const originalPrice = getOriginalShopifyProductPrice(
        currency as Currency,
        shopifyProduct,
        shopifyProductVariant,
        originalPriceSources
      );
      const isValidForRecommendedPrice = condition && conditionToRecommendedDiscount;
      if (!isNil(originalPrice) && isValidForRecommendedPrice) {
        const recommendedPrice =
          getRecommendedPriceForCondition({
            price: convertNumberToMoney(originalPrice.amount, originalPrice.currencyCode),
            condition: condition!,
            conditionToRecommendedDiscount,
            pricingRestrictions,
            tags,
          }) * 0.5;
        return convertNumberToMoney(recommendedPrice, currency);
      }
      return convertNumberToMoney(Number(10), currency);
    }
  }
};

interface GetTradeInPriceOptionsParams {
  treetId: string;
  shopifyProduct?: Product;
  shopifyProductVariant?: ShopifyProductVariantType | null;
  currency?: string;
  isPaidOutOnBundle?: boolean;
  conditionToRecommendedDiscount?: { [condition in Conditions]: number };
  category?: string;
  tradeInPriceRules?: ITradeInPriceRule[];
  originalPriceSources?: OriginalPriceSource[];
  findItemMethod?: FindItemMethod;
}

export const getTradeInCreditPriceOptions = (params: GetTradeInPriceOptionsParams) => {
  const {
    treetId,
    shopifyProduct,
    shopifyProductVariant,
    currency,
    isPaidOutOnBundle,
    conditionToRecommendedDiscount,
    category,
    tradeInPriceRules,
    originalPriceSources,
    findItemMethod,
  } = params;

  let tradeInPriceOptions;

  if (!isEmpty(tradeInPriceRules)) {
    tradeInPriceOptions = tradeInPriceRules?.map(
      ({ target, targetKey }: ITradeInPriceRule) =>
        getTradeInCreditPrice({
          treetId,
          shopifyProduct,
          shopifyProductVariant,
          currency,
          isPaidOutOnBundle,
          condition:
            target === TradeInPriceRuleTarget.ItemWithCondition
              ? (targetKey as Conditions)
              : undefined,
          category: target === TradeInPriceRuleTarget.ItemWithCategory ? targetKey : category,
          tradeInPriceRules,
          originalPriceSources,
          findItemMethod,
        })?.amount
    );
  } else {
    tradeInPriceOptions =
      conditionToRecommendedDiscount &&
      (Object.keys(conditionToRecommendedDiscount) as Conditions[]).map(
        (condition: Conditions) =>
          getTradeInCreditPrice({
            treetId,
            shopifyProduct,
            shopifyProductVariant,
            currency,
            isPaidOutOnBundle,
            condition,
            tradeInPriceRules,
            originalPriceSources,
            findItemMethod,
          })?.amount
      );
  }

  return compact(tradeInPriceOptions) || [];
};

export const getOrderHistoryDescription = (configOrderHistoryDescription?: string) =>
  'Please note that ineligible items from your orders have been hidden. ' +
  `${configOrderHistoryDescription || ''}`;

const matchShopifyProductVariantOnAllOptions = (
  shopifyProductVariants: ShopifyProductVariantType[],
  variantOptionFormValues: {
    [optionName: string]: any;
  }
) => {
  const variantOptionFormValuesValues = Object.values(variantOptionFormValues);

  for (let i = 0; i < shopifyProductVariants.length; i++) {
    const variant = shopifyProductVariants[i];
    const variantOptions = variant.selectedOptions?.map((option) => option.value);
    if (variantOptionFormValuesValues.every((val: any) => variantOptions.includes(val)))
      return variant;
  }

  return null;
};

const findClosestShopifySize = (
  shopifyProductVariants: ShopifyProductVariantType[],
  variantOptionFormValues: { [optionName: string]: any },
  sizeVariantOptionName: string
) => {
  const allReturnedSizeVariantValues = shopifyProductVariants
    .map(
      (variant) =>
        variant.selectedOptions.find(
          (option) => option.name.toLowerCase() === sizeVariantOptionName.toLowerCase()
        )?.value
    )
    .filter((option) => !!option) as string[];

  const userEnteredSizeVariantValue = variantOptionFormValues[sizeVariantOptionName.toLowerCase()];

  if (allReturnedSizeVariantValues?.length > 0) {
    const closestShopifySizeMatch = closestMatch.closestMatch(
      userEnteredSizeVariantValue,
      allReturnedSizeVariantValues
    );

    return closestShopifySizeMatch;
  }
  return null;
};

// Shopify Product Variant querying doesn't allow us to filter on variant options. Thus we need to
// post-filter the query results to find exact matches on the selected variant options. Otherwise,
// we could match on the incorrect variant if there is overlap in the variant values (e.g. size "L"
// and color "Lime Plaid" both have "L"s).
export const findMatchingProductVariant = (
  shopifyProductVariants: ShopifyProductVariantType[],
  variantOptionFormValues: { [optionName: string]: any },
  sizeVariantOptionName: string
) => {
  if (isEmpty(variantOptionFormValues)) {
    // Return first variant if there are no selected variant form values to match on
    return shopifyProductVariants[0];
  }
  // Return variant if form values are a subset of variant options. This is because Ophelia and
  // Indigo have a special case where all listings have a currencyCountry option but we don't
  // want this to be a required user facing selection
  const exactVariantMatch = matchShopifyProductVariantOnAllOptions(
    shopifyProductVariants,
    variantOptionFormValues
  );

  if (exactVariantMatch) return exactVariantMatch;

  // If there aren't any exact matches on user selected options,
  // find the closest shopify size match and try again
  const closestShopifySizeMatch = findClosestShopifySize(
    shopifyProductVariants,
    variantOptionFormValues,
    sizeVariantOptionName
  );

  if (closestShopifySizeMatch) {
    const variantOptionFormValuesWithShopifySizeMatch = {
      ...variantOptionFormValues,
      [sizeVariantOptionName.toLowerCase()]: closestShopifySizeMatch,
    };

    const matchForApproxShopifySize = matchShopifyProductVariantOnAllOptions(
      shopifyProductVariants,
      variantOptionFormValuesWithShopifySizeMatch
    );

    if (matchForApproxShopifySize) return matchForApproxShopifySize;
  }

  // If we have no matches, but there is exactly one variant
  // returned by the shopify query, return that.
  if (shopifyProductVariants.length === 1) {
    return shopifyProductVariants[0];
  }

  return null;
};

// Combine saved and unsaved form values to display in form.
export const getAllListingAttributes = (
  sharetribeListing: OwnListingWithImages,
  unsavedListingValues: Partial<OwnListingWithImages['attributes']> | null
) => {
  // This feature does not depend on treetId, so passing in an empty string instead.
  const isGuestListingEnabled = isFeatureEnabled(Feature.GuestListing, '');

  if (!isGuestListingEnabled) return sharetribeListing.attributes;

  const savedAttribues = sharetribeListing.attributes;

  const combinedAttributes = mergeWith({}, savedAttribues, unsavedListingValues, mergeCustomizer);

  const { price } = combinedAttributes;

  // Price populates as a Money-like object, but will not render in the
  // selling price field unless it is actually a Money type.
  if (price && !(price instanceof Money)) {
    combinedAttributes.price = new Money(price.amount, price.currency);
  }

  return combinedAttributes;
};

export const getElapsedTimeSinceListingStart = (startTime: number | null): number | undefined => {
  if (!startTime) return undefined;

  return Date.now().valueOf() - Number(startTime);
};

// Value Store pattern
// - Send dispatch from component to set values in Remote Value Store & state.
// - Ensure encoded data is being passed to any Listing Flow redirects.
// - Hydrate relevant state in loadData.
// - Clear Remote Value Store & state after publish.
interface ValueStore {
  // Form Values will get saved to the listing.
  formValues?: Partial<OwnListing['attributes']> | null;
}

export const getRemoteValueStore = async (): Promise<ValueStore> => {
  const listingFlowId = getUrlHash()?.substring(1);
  if (!listingFlowId) {
    return {};
  }

  const [compressedListing] = await handle(getDraftListing(listingFlowId));
  let remoteValues = {};
  if (compressedListing) {
    const decompressedValues = LZString.decompressFromEncodedURIComponent(compressedListing);
    remoteValues = JSON.parse(decompressedValues || '{}');
    return remoteValues;
  }
  return {};
};

const compressValuesForRemoteStorage = (values: ValueStore): string =>
  LZString.compressToEncodedURIComponent(JSON.stringify(values));

export const saveListingFlowIdToUrl = (listingFlowId: string): void => {
  if (typeof window !== 'undefined') {
    // Use native `replaceState` as to not trigger a rerender with react-router.
    const { search } = window.location;
    window.history.replaceState({}, '', `${search}#${listingFlowId}`);
  }
};

// debounce wait time since it isn't crucial to send updates immediately
const debouncedUpdateDraftListing = debounce(updateDraftListing, DEBOUNCE_WAIT_TIME);

export const updateRemoteValueStore = async (
  newValues: ValueStore,
  existingValues?: ValueStore,
  shouldUpdateImmediately?: boolean
): Promise<ValueStore> => {
  let listingFlowId: string = getUrlHash()?.substring(1) || '';
  let listingFlowIdMergeObject = {};

  if (!listingFlowId) {
    listingFlowId = newValues?.formValues?.publicData?.listingFlowId || uuidv4();
    listingFlowIdMergeObject = {
      formValues: {
        publicData: { listingFlowId },
      },
    };
    saveListingFlowIdToUrl(listingFlowId);
  }

  const mergedValues = mergeWith(
    existingValues || {},
    newValues,
    listingFlowIdMergeObject,
    mergeCustomizer
  );

  const compressedValues = compressValuesForRemoteStorage(mergedValues);

  if (shouldUpdateImmediately) {
    await updateDraftListing(listingFlowId, compressedValues);
  } else {
    debouncedUpdateDraftListing(listingFlowId, compressedValues);
  }

  // Return uncompressed values.
  return mergedValues;
};

export const determinePriceDropEnabledInitialValue = (
  isActiveFromConfig: boolean | undefined,
  isEnabledFromListing: boolean | undefined,
  isListingPublished: boolean | undefined
): boolean => {
  switch (true) {
    case !isNil(isEnabledFromListing):
      return isEnabledFromListing!;
    case !isNil(isActiveFromConfig):
      return isActiveFromConfig!;
    // Ensures listings that didn't have the option of opting in,
    // aren't opted in while editing the listing.
    case isNil(isActiveFromConfig) && isNil(isEnabledFromListing) && isListingPublished:
      return false;
    default:
      // TODO: Revisit auto opt-in.
      return false;
  }
};

export const determinePriceDropMinPriceInitialValue = (
  minPriceFromConfig: number | undefined,
  minPriceFromListing: number | undefined,
  defaultSellingPrice: MoneyType | undefined,
  currency: string | undefined
): MoneyType | undefined => {
  switch (true) {
    case !!minPriceFromListing:
      return convertNumberToMoney(minPriceFromListing, currency);
    case !!minPriceFromConfig:
      return convertNumberToMoney(minPriceFromConfig! / 100, currency);
    case !!defaultSellingPrice:
      return getRecommendedMinPriceForPriceDrop(defaultSellingPrice);
    default:
      return undefined;
  }
};

export const determineAnchorPriceInitialValue = (
  anchorPriceFromConfig: number | undefined,
  anchorPriceFromListing: number | undefined,
  defaultSellingPrice: MoneyType | undefined,
  currency: string | undefined
) => {
  switch (true) {
    case !!anchorPriceFromListing:
      return convertNumberToMoney(anchorPriceFromListing, currency);
    case !!anchorPriceFromConfig:
      return convertNumberToMoney(anchorPriceFromConfig! / 100, currency);
    case !!defaultSellingPrice:
      return defaultSellingPrice;
    default:
      return undefined;
  }
};
