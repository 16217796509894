import { ICustomIcon } from '../types/contentful/types.generated';
import { useShopConfig } from './shopConfig';

export enum CustomIconType {
  Favorite = 'FAVORITE',
  FavoriteFilled = 'FAVORITE_FILLED',
  User = 'USER',
  ShoppingBag = 'SHOPPING_BAG',
  Search = 'SEARCH',
  Filter = 'FILTER',
  BuyerProtectionFilled = 'BUYER_PROTECTION_FILLED',
  CashFilled = 'CASH_FILLED',
  TreeFilled = 'TREE_FILLED',
  SocialMediaFacebook = 'SOCIAL_MEDIA_FACEBOOK',
  SocialMediaInstagram = 'SOCIAL_MEDIA_INSTAGRAM',
  SocialMediaPinterest = 'SOCIAL_MEDIA_PINTEREST',
  SocialMediaTiktok = 'SOCIAL_MEDIA_TIKTOK',
  SocialMediaYoutube = 'SOCIAL_MEDIA_YOUTUBE',
  SocialMediaX = 'SOCIAL_MEDIA_X',
}

export const useCustomIcon = (iconType: CustomIconType): ICustomIcon | undefined => {
  const { css: shopCss } = useShopConfig();
  const { customIconsCollection } = shopCss;
  return customIconsCollection?.items?.find((icon: ICustomIcon) => icon.type === iconType);
};
