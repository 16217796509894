import { ListingCondition, FindItemMethod as PrismaFindItemMethod } from '@prisma/client';
import { Conditions } from '../../shopConfig/filters/condition';
import {
  LISTING_STATE_CLOSED,
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_PUBLISHED,
} from '../../util/types';
import { ListingState } from '../apollo/generated/types.generated';
import { FindItemMethod } from '../sharetribe/listing';

export const ST_LISTING_STATE_TO_LISTING_STATUS: {
  [sharetribeState: string]: ListingState;
} = {
  // TODO: add ListingState.DRAFT
  [LISTING_STATE_PENDING_APPROVAL]: ListingState.PendingApproval,
  [LISTING_STATE_PUBLISHED]: ListingState.Open,
  [LISTING_STATE_CLOSED]: ListingState.Closed,
};

export const ST_CONDITION_TO_LISTING_CONDITION: { [key: string]: ListingCondition } = {
  [Conditions.NewWithTags]: ListingCondition.NEW_WITH_TAGS,
  [Conditions.NewWithoutTags]: ListingCondition.NEW_WITHOUT_TAGS,
  [Conditions.Excellent]: ListingCondition.EXCELLENT,
  [Conditions.Good]: ListingCondition.GOOD,
  [Conditions.MinorDefect]: ListingCondition.MINOR_DEFECT,
};

export const ST_FIND_ITEM_METHOD_TO_LISTING_FIND_ITEM_METHOD: {
  [key: string]: PrismaFindItemMethod;
} = {
  [FindItemMethod.Upload]: PrismaFindItemMethod.UPLOAD,
  [FindItemMethod.ApiUpload]: PrismaFindItemMethod.API_UPLOAD,
  [FindItemMethod.Search]: PrismaFindItemMethod.SEARCH,
  [FindItemMethod.EmailAccount]: PrismaFindItemMethod.EMAIL_ACCOUNT,
  [FindItemMethod.OrderNumber]: PrismaFindItemMethod.ORDER_NUMBER,
  [FindItemMethod.CannotFind]: PrismaFindItemMethod.CANNOT_FIND,
  [FindItemMethod.AllProducts]: PrismaFindItemMethod.ALL_PRODUCTS,
  [FindItemMethod.Manual]: PrismaFindItemMethod.MANUAL,
  [FindItemMethod.ListAnyBrand]: PrismaFindItemMethod.LIST_ANY_BRAND,
  [FindItemMethod.TradeInUpload]: PrismaFindItemMethod.TRADE_IN_UPLOAD,
  [FindItemMethod.RelistAsDuplicate]: PrismaFindItemMethod.RELIST_AS_DUPLICATE,
  [FindItemMethod.RelistFromTreet]: PrismaFindItemMethod.RELIST_FROM_TREET,
};

// Create the reverse lookup maps
export const LISTING_CONDITION_TO_ST_CONDITION = Object.entries(
  ST_CONDITION_TO_LISTING_CONDITION
).reduce((acc, [key, value]) => {
  acc[value] = key;
  return acc;
}, {} as { [key in ListingCondition]: string });

export const LISTING_FIND_ITEM_METHOD_TO_ST_FIND_ITEM_METHOD = Object.entries(
  ST_FIND_ITEM_METHOD_TO_LISTING_FIND_ITEM_METHOD
).reduce((acc, [key, value]) => {
  acc[value] = key;
  return acc;
}, {} as { [key in PrismaFindItemMethod]: string });
