/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { BuilderSections } from '../../util/builder';

const pactSizes = ['XXS', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'One Size'];

export const pactShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getConditionsFilterConfig(),
    getBrandDirectFilterConfig(),
    getSizeFilterConfig({ sizes: pactSizes }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '38630ab1383948449bdfcae059254593',
    },
  },
};
