import React, { useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import TypographyWrapper from '../TypographyWrapper/TypographyWrapper';
import { BundleInfo } from '../../types/models/bundle';
import { ListingWithImages } from '../../types/sharetribe/listing';
import { LineItemCode } from '../../types/apollo/generated/types.generated';
import ReturnLineItemBreakdown from './ReturnLineItemBreakdown';
import ItemizedListing from '../ItemizedListing/ItemizedListing';
import { CheckoutLineItem } from '../../types/models/lineItem';
import { useAppDispatch } from '../../hooks/appDispatch';
import { calculateReturnAmountForDisputeBundleItems } from '../../containers/ManagePurchasesPage/ManagePurchasesPage.duck';
import { useShopConfig } from '../../hooks/shopConfig';
import { RequestStatus } from '../../types/requestStatus';
import IconSpinner from '../Icons/IconSpinner/IconSpinner';

export const DISPUTE_MODAL_SUMMARY_PAGE_REFERRER_LOCATION = 'Dispute Modal Summary Page';

interface GetItemizedListingsParams {
  disputedBundleItemIds?: string[];
  bundleItemToListingIdMap?: Map<string, string>;
  listingsMap?: Map<string, ListingWithImages>;
  bundle?: BundleInfo;
}

const getItemizedListings = ({
  disputedBundleItemIds,
  bundleItemToListingIdMap,
  listingsMap,
  bundle,
}: GetItemizedListingsParams) =>
  disputedBundleItemIds?.map((bundleItemId) => {
    const sharetribeListingId = bundleItemToListingIdMap?.get(bundleItemId) ?? '';
    const listing = listingsMap?.get(sharetribeListingId);

    const listingLineItem = bundle?.lineItems.find(
      (lineItem) =>
        lineItem.code === LineItemCode.Listing &&
        lineItem.listingLineItem?.sharetribeListingId === listing?.id.uuid
    );

    return (
      listing && (
        <ItemizedListing
          lineItem={listingLineItem as CheckoutLineItem}
          referrerForLogging={DISPUTE_MODAL_SUMMARY_PAGE_REFERRER_LOCATION}
          listing={listing}
        />
      )
    );
  });

interface ReturnSummaryProps {
  bundle?: BundleInfo;
  bundleItemToListingIdMap?: Map<string, string>;
  disputedBundleItemIds?: string[];
  listingsMap?: Map<string, ListingWithImages>;
}

const ReturnSummary = ({
  bundle,
  bundleItemToListingIdMap,
  disputedBundleItemIds,
  listingsMap,
}: ReturnSummaryProps) => {
  const dispatch = useAppDispatch();
  const { returnShippingPrice } = useShopConfig();
  const { returnAmount, calculateReturnAmountStatus } = useSelector<any>(
    (state) => state.ManagePurchasesPage
  ) as any;

  const bundleId = bundle?.id;

  useEffect(() => {
    if (bundleId && disputedBundleItemIds && disputedBundleItemIds.length > 0) {
      dispatch(
        calculateReturnAmountForDisputeBundleItems(
          bundleId,
          disputedBundleItemIds,
          returnShippingPrice?.price
        )
      );
    }
  }, [bundleId, disputedBundleItemIds, returnShippingPrice]);

  const returnItemizedListings = getItemizedListings({
    disputedBundleItemIds,
    bundleItemToListingIdMap,
    listingsMap,
    bundle,
  });

  if (calculateReturnAmountStatus === RequestStatus.Pending) {
    return (
      <Box display="flex" justifyContent="center" mb={3}>
        <IconSpinner />
      </Box>
    );
  }

  return (
    <Box p={2}>
      <Grid container alignItems="center" spacing={2}>
        {returnItemizedListings}
        <ReturnLineItemBreakdown
          bundle={bundle}
          disputedBundleItemIds={disputedBundleItemIds}
          returnAmount={returnAmount}
        />
        <Box mt={2}>
          <TypographyWrapper variant="body2">
            A return label will be sent to your email address. Once your return is received and
            processed, you will be refunded the above amount.
          </TypographyWrapper>
        </Box>
      </Grid>
    </Box>
  );
};

export default ReturnSummary;
