import { Box, Typography } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Error, IconSpinner } from '..';
import { useShopConfig } from '../../hooks/shopConfig';
import { ensureCurrentUser } from '../../util/data';
import { parse, PRESERVED_SEARCH_PARAMS } from '../../util/urlHelpers';
import CannotFindItemLink from '../../containers/EditListingPage/CannotFindItemLink';
import { useAllowedFindItemMethods } from '../../hooks/useAllowedFindItemMethods';
import { useCurrentUserPermissions } from '../../hooks/useUserPermissions';
import { ButtonVariant } from '../Button/Button';
import { getShopifyOrders } from '../../util/api';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import { useLazyApi } from '../../hooks/useLazyApi';
import { FindItemMethod } from '../../types/sharetribe/listing';
import ShopifyOrder from './ShopifyOrder';
import css from './Shopify.module.css';

interface EmptyProps {
  email: string;
}

const Empty: FC<EmptyProps> = (props) => {
  const { email } = props;
  return <Typography variant="body1">No orders found for {email}.</Typography>;
};

const Loading: FC = () => (
  <Box display="flex" justifyContent="center" py={2}>
    <IconSpinner />
  </Box>
);

interface ShowMoreButtonProps {
  ordersMetadata?: any;
  onClick?: (params: any) => void;
}

const ShowMoreButton: FC<ShowMoreButtonProps> = (props) => {
  const { ordersMetadata, onClick } = props;

  const hasMoreOrders = ordersMetadata?.pageInfo?.hasNextPage;
  if (!hasMoreOrders) return null;

  return (
    <Box display="flex" justifyContent="center" pt={1}>
      <Button variant={ButtonVariant.Primary} className={css.showMoreButton} onClick={onClick}>
        Show More Orders
      </Button>
    </Box>
  );
};

const ShopifyOrders: FC = () => {
  const currentUser = useSelector<any>((state) => state.user.currentUser);
  const { shouldAllowSearch, shouldAllowCannotFind } = useAllowedFindItemMethods();
  const shopConfig = useShopConfig();
  const { additionalOrdersQuery, shopId } = shopConfig;
  const { countryCode } = useBrandCountryConfig();

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const { email: currentUserEmail, id: currentUserId } = ensuredCurrentUser.attributes;

  const location = useLocation();
  const { isTreet } = useCurrentUserPermissions();
  const emailOverride = parse(location.search)[PRESERVED_SEARCH_PARAMS.ShopifyEmailOverride];

  const email = isTreet ? emailOverride || currentUserEmail : currentUserEmail;

  const getShopifyOrdersUpdateQuery = (previousResult?: any, fetchMoreResult?: any) => ({
    ...previousResult,
    shopifyOrders: {
      ...previousResult?.shopifyOrders,
      pageInfo: {
        ...fetchMoreResult?.shopifyOrders.pageInfo,
      },
      edges: [
        ...(previousResult?.shopifyOrders.edges || []),
        ...(fetchMoreResult?.shopifyOrders.edges || []),
      ],
    },
  });

  const {
    lazyQuery: lazyGetShopifyOrders,
    loading: areOrdersByEmailLoading,
    data: ordersData,
    error: getOrdersError,
  } = useLazyApi(getShopifyOrders, getShopifyOrdersUpdateQuery);

  const ordersMetadata = ordersData?.shopifyOrders;
  const orders = ordersMetadata?.edges;

  const fetchOrders = () => {
    const cursor = orders?.slice(-1)[0]?.cursor;
    lazyGetShopifyOrders({
      currentUserId,
      email: currentUserEmail,
      cursor,
      countryCode,
      additionalOrdersQuery,
      subdomain: shopId,
    });
  };

  useEffect(() => {
    if (email) fetchOrders();
  }, [email]);
  const areOrdersEmpty = isEmpty(orders);

  if (getOrdersError) {
    return (
      <Error>
        There was an error fetching the orders from your account. Please refresh the page or try
        searching for your item instead.
      </Error>
    );
  }

  if (!currentUser || (areOrdersByEmailLoading && areOrdersEmpty)) return <Loading />;

  if (areOrdersEmpty) return <Empty email={email} />;

  return (
    <>
      {orders.map((order: any) => (
        <ShopifyOrder
          order={order}
          findItemMethod={FindItemMethod.EmailAccount}
          key={order.node?.name}
        />
      ))}
      {areOrdersByEmailLoading && <Loading />}
      <ShowMoreButton ordersMetadata={ordersMetadata} onClick={fetchOrders} />
      {!shouldAllowSearch && shouldAllowCannotFind && (
        <Box py={2} display="flex" justifyContent="center">
          <CannotFindItemLink />
        </Box>
      )}
    </>
  );
};

export default ShopifyOrders;
