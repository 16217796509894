import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { NamedLinkComponent } from './NamedLinkComponent';

interface NamedLinkComponentProps {
  name: string;
  params?: Record<string, any>;
  to?: Record<string, any>;
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  activeClassName?: string;
  title?: string;
  target?: string;
  onClick?: () => void;
}
type NamedLinkProps = RouteComponentProps<any> & NamedLinkComponentProps;

export default withRouter<NamedLinkProps, typeof NamedLinkComponent>(
  NamedLinkComponent
) as unknown as React.ComponentType<NamedLinkComponentProps>;
