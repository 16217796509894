import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { NotFoundPage } from '../containers';
import { NamedRedirect } from '../components';
import { locationChanged } from '../ducks/Routing.duck';
import { propTypes } from '../util/types';
import * as log from '../util/log';
import { canonicalRoutePath } from '../util/routes';
import { useRouteConfiguration } from '../hooks/useRouteConfiguration';
import { parse } from '../util/urlHelpers';

const { arrayOf, bool, object, func, shape, string } = PropTypes;
export const NO_SCROLL = 'no_scroll';

const canShowComponent = (props) => {
  const { isAuthenticated, route } = props;
  const { auth } = route;
  return !auth || isAuthenticated;
};

const callLoadData = (props) => {
  const { match, location, route, dispatch, logoutInProgress } = props;
  const { loadData, name } = route;
  const shouldLoadData =
    typeof loadData === 'function' && canShowComponent(props) && !logoutInProgress;

  if (shouldLoadData) {
    dispatch(loadData(match.params, location.search))
      .then(() => {
        // eslint-disable-next-line no-console
        console.log(`loadData success for ${name} route`);
      })
      .catch((e) => {
        log.error(e, 'load-data-failed', { routeName: name });
      });
  }
};

const setPageScrollPosition = (location) => {
  const scrollToTop = () =>
    window.scroll({
      top: 0,
      left: 0,
    });

  try {
    const el = location?.hash && document.querySelector(location.hash);
    if (el) {
      // Found element with the given fragment identifier, scrolling
      // to that element.
      //
      // NOTE: This isn't foolproof. It works when navigating within
      // the application between pages and within a single page. It
      // also works with the initial page load. However, it doesn't
      // seem work work properly when refreshing the page, at least
      // not in Chrome.
      //
      // TODO: investigate why the scrolling fails on refresh
      requestAnimationFrame(() => {
        el.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        });
      });
    }
    // Sometimes we want to preserve scroll position on route changes ex. consolidated listings feature
    const queryParams = parse(location.search);
    if (queryParams[NO_SCROLL]) {
      return null;
    }
  } catch (e) {
    // Don't error.
  }
  return requestAnimationFrame(scrollToTop);
};

const handleLocationChanged = (dispatch, location, routes) => {
  setPageScrollPosition(location);
  const url = canonicalRoutePath(routes, location);
  dispatch(locationChanged(location, url));
};

const RouteComponentRenderer = (props) => {
  const { dispatch, route, match, location, staticContext } = props;

  const routes = useRouteConfiguration();

  useEffect(() => {
    callLoadData(props);
    handleLocationChanged(dispatch, location, routes);
  }, [location]);

  const { component: RouteComponent, authPage = 'SignupPage', extraProps } = route;
  const canShow = canShowComponent(props);

  if (!canShow) {
    staticContext.unauthorized = true;
  }

  return canShow ? (
    <RouteComponent params={match.params} location={location} {...extraProps} />
  ) : (
    <NamedRedirect
      name={authPage}
      state={{ from: `${location.pathname}${location.search}${location.hash}` }}
    />
  );
};

RouteComponentRenderer.propTypes = {
  isAuthenticated: bool.isRequired,
  logoutInProgress: bool.isRequired,
  route: propTypes.route.isRequired,
  match: shape({
    params: object.isRequired,
    url: string.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,
  staticContext: object.isRequired,
  dispatch: func.isRequired,
};

const Routes = (props) => {
  const { isAuthenticated, logoutInProgress, staticContext, dispatch, routes } = props;
  const toRouteComponent = (route) => {
    const renderProps = {
      isAuthenticated,
      logoutInProgress,
      route,
      staticContext,
      dispatch,
    };

    // By default, our routes are exact.
    // https://reacttraining.com/react-router/web/api/Route/exact-bool
    const isExact = route.exact != null ? route.exact : true;
    return (
      <Route
        key={route.name}
        path={route.path}
        exact={isExact}
        render={(matchProps) => (
          <RouteComponentRenderer
            {...renderProps}
            match={matchProps.match}
            location={matchProps.location}
          />
        )}
      />
    );
  };

  // N.B. routes prop within React Router needs to stay the same,
  // so that React is is not rerendering page component.
  // That's why we pass-in props.routes instead of calling routeConfiguration here.
  return (
    <Switch>
      {routes.map(toRouteComponent)}
      <Route component={NotFoundPage} />
    </Switch>
  );
};

Routes.defaultProps = { staticContext: {} };

Routes.propTypes = {
  isAuthenticated: bool.isRequired,
  logoutInProgress: bool.isRequired,
  routes: arrayOf(propTypes.route).isRequired,

  // from withRouter
  staticContext: object,

  // from connect
  dispatch: func.isRequired,
};

const mapStateToProps = (state) => {
  const { isAuthenticated, logoutInProgress } = state.Auth;
  return { isAuthenticated, logoutInProgress };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
export default compose(withRouter, connect(mapStateToProps))(Routes);
