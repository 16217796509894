import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import moment from 'moment';
import { EXTENDED_SHIPPING_WINDOW } from '../ManageSalesPage/ManageSalesPage.duck';
import {
  CoreAddress,
  LineItem,
  LineItemCode,
  UpdateBundleDocument,
} from '../../types/apollo/generated/types.generated';
import { BundleWithWeight } from '../../types/models/bundle';
import { StorableError } from '../../types/error';
import { RequestStatus } from '../../types/requestStatus';
import { SharetribeAddress } from '../../types/sharetribe/address';
import { OwnListingWithImages } from '../../types/sharetribe/listing';
import { createShippingLabelForBundle, validateAddress } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { apolloClient } from '../../apollo';
import { handle } from '../../util/helpers';

// ================ Action types ================ //

const RESET_INITIAL_STATE = 'app/GenerateShippingLabelPage/RESET_INITIAL_STATE';

const FETCH_BUNDLE_LISTINGS_REQUEST = 'app/GenerateShippingLabelPage/FETCH_BUNDLE_LISTINGS_REQUEST';
const FETCH_BUNDLE_LISTINGS_SUCCESS = 'app/GenerateShippingLabelPage/FETCH_BUNDLE_LISTINGS_SUCCESS';
const FETCH_BUNDLE_LISTINGS_ERROR = 'app/GenerateShippingLabelPage/FETCH_BUNDLE_LISTINGS_ERROR';

const SET_WEIGHT = 'app/GenerateShippingLabelPage/SET_WEIGHT';

const VALIDATE_SHIPPING_ADDRESS_REQUEST =
  'app/GenerateShippingLabelPage/VALIDATE_SHIPPING_ADDRESS_REQUEST';
const VALIDATE_SHIPPING_ADDRESS_SUCCESS =
  'app/GenerateShippingLabelPage/VALIDATE_SHIPPING_ADDRESS_SUCCESS';
const VALIDATE_SHIPPING_ADDRESS_ERROR =
  'app/GenerateShippingLabelPage/VALIDATE_SHIPPING_ADDRESS_ERROR';

const UPDATE_LISTINGS_REQUEST = 'app/GenerateShippingLabelPage/UPDATE_LISTINGS_REQUEST';
const UPDATE_LISTINGS_SUCCESS = 'app/GenerateShippingLabelPage/UPDATE_LISTINGS_SUCCESS';
const UPDATE_LISTINGS_ERROR = 'app/GenerateShippingLabelPage/UPDATE_LISTINGS_ERROR';

const CREATE_SHIPPING_LABEL_REQUEST = 'app/GenerateShippingLabelPage/CREATE_SHIPPING_LABEL_REQUEST';
const CREATE_SHIPPING_LABEL_SUCCESS = 'app/GenerateShippingLabelPage/CREATE_SHIPPING_LABEL_SUCCESS';
const CREATE_SHIPPING_LABEL_ERROR = 'app/GenerateShippingLabelPage/CREATE_SHIPPING_LABEL_ERROR';

interface ResetInitialState {
  type: typeof RESET_INITIAL_STATE;
}

interface FetchBundleListingsRequest {
  type: typeof FETCH_BUNDLE_LISTINGS_REQUEST;
}

interface FetchBundleListingsSuccess {
  type: typeof FETCH_BUNDLE_LISTINGS_SUCCESS;
  bundle: BundleWithWeight;
  buyerName: string;
  listings: OwnListingWithImages[];
}

interface FetchBundleListingsError {
  type: typeof FETCH_BUNDLE_LISTINGS_ERROR;
  error: StorableError;
}

interface SetWeight {
  type: typeof SET_WEIGHT;
  weight: number | null;
  weightUnit: string | null;
}

interface ValidateShippingAddressRequest {
  type: typeof VALIDATE_SHIPPING_ADDRESS_REQUEST;
}

interface ValidateShippingAddressSuccess {
  type: typeof VALIDATE_SHIPPING_ADDRESS_SUCCESS;
}

interface ValidateShippingAddressError {
  type: typeof VALIDATE_SHIPPING_ADDRESS_ERROR;
  error: StorableError;
}

interface UpdateListingsRequest {
  type: typeof UPDATE_LISTINGS_REQUEST;
}

interface UpdateListingsSuccess {
  type: typeof UPDATE_LISTINGS_SUCCESS;
}

interface UpdateListingsError {
  type: typeof UPDATE_LISTINGS_ERROR;
  error: StorableError;
}

interface CreateShippingLabelRequest {
  type: typeof CREATE_SHIPPING_LABEL_REQUEST;
}

interface CreateShippingLabelSuccess {
  type: typeof CREATE_SHIPPING_LABEL_SUCCESS;
  labelUrl: string;
}

interface CreateShippingLabelError {
  type: typeof CREATE_SHIPPING_LABEL_ERROR;
  error: StorableError;
}

type GenerateShippingLabelPageActionType =
  | ResetInitialState
  | FetchBundleListingsRequest
  | FetchBundleListingsSuccess
  | FetchBundleListingsError
  | SetWeight
  | ValidateShippingAddressRequest
  | ValidateShippingAddressSuccess
  | ValidateShippingAddressError
  | UpdateListingsRequest
  | UpdateListingsSuccess
  | UpdateListingsError
  | CreateShippingLabelRequest
  | CreateShippingLabelSuccess
  | CreateShippingLabelError;

// ================ Reducer ================ //

export interface GenerateShippingLabelPageState {
  fetchBundleListingsStatus: RequestStatus;
  fetchBundleListingsError: StorableError | null;
  bundle: BundleWithWeight | null;
  buyerName: string;
  listings: OwnListingWithImages[];
  weightWithUnit: { weight: number | null; weightUnit: string | null } | null;
  validateShipFromStatus: RequestStatus;
  validateShipFromError: StorableError | null;
  updateListingsStatus: RequestStatus;
  updateListingsError: StorableError | null;
  createShippingLabelStatus: RequestStatus;
  createShippingLabelError: StorableError | null;
  labelUrl: string;
}

const initialState = {
  fetchBundleListingsStatus: RequestStatus.Ready,
  fetchBundleListingsError: null,
  bundle: null,
  buyerName: '',
  listings: [],
  weightWithUnit: null,
  validateShipFromStatus: RequestStatus.Ready,
  validateShipFromError: null,
  updateListingsStatus: RequestStatus.Ready,
  updateListingsError: null,
  createShippingLabelStatus: RequestStatus.Ready,
  createShippingLabelError: null,
  labelUrl: '',
};

export default function generateShippingLabelPageReducer(
  state: GenerateShippingLabelPageState = initialState,
  action: GenerateShippingLabelPageActionType
): GenerateShippingLabelPageState {
  switch (action.type) {
    case RESET_INITIAL_STATE: {
      return initialState;
    }
    case FETCH_BUNDLE_LISTINGS_REQUEST: {
      return {
        ...state,
        fetchBundleListingsStatus: RequestStatus.Pending,
        fetchBundleListingsError: null,
      };
    }
    case FETCH_BUNDLE_LISTINGS_SUCCESS: {
      return {
        ...state,
        fetchBundleListingsStatus: RequestStatus.Success,
        bundle: action.bundle,
        buyerName: action.buyerName,
        listings: action.listings,
      };
    }
    case FETCH_BUNDLE_LISTINGS_ERROR: {
      return {
        ...state,
        fetchBundleListingsStatus: RequestStatus.Error,
        fetchBundleListingsError: action.error,
      };
    }
    case SET_WEIGHT: {
      return {
        ...state,
        weightWithUnit: { weight: action.weight, weightUnit: action.weightUnit },
        validateShipFromStatus: RequestStatus.Ready,
        validateShipFromError: null,
        updateListingsStatus: RequestStatus.Ready,
        updateListingsError: null,
        createShippingLabelStatus: RequestStatus.Ready,
        createShippingLabelError: null,
      };
    }
    case VALIDATE_SHIPPING_ADDRESS_REQUEST:
      return {
        ...state,
        validateShipFromStatus: RequestStatus.Pending,
        validateShipFromError: null,
      };
    case VALIDATE_SHIPPING_ADDRESS_SUCCESS:
      return { ...state, validateShipFromStatus: RequestStatus.Success };
    case VALIDATE_SHIPPING_ADDRESS_ERROR:
      return {
        ...state,
        validateShipFromStatus: RequestStatus.Error,
        validateShipFromError: action.error,
      };
    case UPDATE_LISTINGS_REQUEST: {
      return {
        ...state,
        updateListingsStatus: RequestStatus.Pending,
        updateListingsError: null,
      };
    }
    case UPDATE_LISTINGS_SUCCESS: {
      return { ...state, updateListingsStatus: RequestStatus.Success };
    }
    case UPDATE_LISTINGS_ERROR: {
      return {
        ...state,
        updateListingsStatus: RequestStatus.Error,
        updateListingsError: action.error,
      };
    }
    case CREATE_SHIPPING_LABEL_REQUEST: {
      return {
        ...state,
        createShippingLabelStatus: RequestStatus.Pending,
        createShippingLabelError: null,
      };
    }
    case CREATE_SHIPPING_LABEL_SUCCESS: {
      return {
        ...state,
        createShippingLabelStatus: RequestStatus.Success,
        labelUrl: action.labelUrl,
      };
    }
    case CREATE_SHIPPING_LABEL_ERROR: {
      return {
        ...state,
        createShippingLabelStatus: RequestStatus.Error,
        createShippingLabelError: action.error,
      };
    }
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const resetInitialState = () => ({ type: RESET_INITIAL_STATE });

const fetchBundleListingsRequest = () => ({
  type: FETCH_BUNDLE_LISTINGS_REQUEST,
});

const fetchBundleListingsSuccess = (
  bundle: BundleWithWeight,
  buyerName: string,
  listings: OwnListingWithImages[]
) => ({
  type: FETCH_BUNDLE_LISTINGS_SUCCESS,
  bundle,
  buyerName,
  listings,
});

const fetchBundleListingsError = (error: StorableError) => ({
  type: FETCH_BUNDLE_LISTINGS_ERROR,
  error,
});

export const setWeight = (weight: number | null, weightUnit: string | null) => ({
  type: SET_WEIGHT,
  weight,
  weightUnit,
});

const validateShippingAddressRequest = () => ({ type: VALIDATE_SHIPPING_ADDRESS_REQUEST });

const validateShippingAddressSuccess = () => ({ type: VALIDATE_SHIPPING_ADDRESS_SUCCESS });

const validateShippingAddressError = (error: StorableError) => ({
  type: VALIDATE_SHIPPING_ADDRESS_ERROR,
  error,
});

const updateListingsRequest = () => ({ type: UPDATE_LISTINGS_REQUEST });

const updateListingsSuccess = () => ({ type: UPDATE_LISTINGS_SUCCESS });

const updateListingsError = (error: StorableError) => ({ type: UPDATE_LISTINGS_ERROR, error });

const createShippingLabelRequest = () => ({ type: CREATE_SHIPPING_LABEL_REQUEST });

const createShippingLabelSuccess = (labelUrl: string) => ({
  type: CREATE_SHIPPING_LABEL_SUCCESS,
  labelUrl,
});

const createShippingLabelError = (error: StorableError) => ({
  type: CREATE_SHIPPING_LABEL_ERROR,
  error,
});

// ================ Thunks ================ //

export const getBundleListings =
  (bundle: BundleWithWeight) => async (dispatch: Dispatch, getState: () => any, sdk: any) => {
    const { bundleItems, order } = bundle;
    dispatch(fetchBundleListingsRequest());

    try {
      const buyer = denormalisedResponseEntities(
        await sdk.users.show({ id: order?.sharetribeBuyerId })
      )[0];

      const buyerName = buyer?.attributes?.profile?.displayName;

      const listingResponses = await Promise.all(
        bundleItems.map((bundleItem) =>
          sdk.ownListings.show({
            id: bundleItem.listing.sharetribeListingId,
            include: ['images', 'privateData'],
            'fields.image': ['variants.default'],
          })
        )
      );

      const ownListings = listingResponses.map(
        (response) => denormalisedResponseEntities(response)[0]
      ) as OwnListingWithImages[];
      dispatch(fetchBundleListingsSuccess(bundle, buyerName, ownListings));

      return ownListings;
    } catch (e) {
      dispatch(fetchBundleListingsError(storableError(e)));
      log.error(e as Error, 'fetch-bundle-listings-failed', {});
      return undefined;
    }
  };

export const validateShippingAddress =
  (address: SharetribeAddress, shouldBypassAddressValidation: boolean) =>
  async (dispatch: ThunkDispatch<any, any, any>) => {
    dispatch(validateShippingAddressRequest());

    try {
      const response = await validateAddress({ address, shouldBypassAddressValidation });
      dispatch(validateShippingAddressSuccess());
      return response;
    } catch (e) {
      const error = e.error || storableError(e);
      dispatch(validateShippingAddressError(error));
      // Throw the storable error so that submission validation displays the correct text
      throw error;
    }
  };

interface UpdateListingsParams {
  privateData: { shipFromAddress: SharetribeAddress };
}

export const updateListings =
  (params: UpdateListingsParams) =>
  async (dispatch: ThunkDispatch<any, any, any>, getState: () => any, sdk: any) => {
    const { listings } = getState().GenerateShippingLabelPage as GenerateShippingLabelPageState;
    dispatch(updateListingsRequest());

    try {
      const updateResponse = await Promise.all(
        listings.map(async (listing) => {
          // TODO: Listing-Migration After migrating listings to PG, ST update can be removed
          const ownListingResponse = await sdk.ownListings.update(
            { ...params, id: listing.id },
            { expand: true, include: ['images'] }
          );
          dispatch(addMarketplaceEntities(ownListingResponse));
          return ownListingResponse;
        })
      );

      dispatch(updateListingsSuccess());
      return updateResponse;
    } catch (e) {
      log.error(e as Error, 'generate-label-update-listings-failed', { params, listings });
      return dispatch(updateListingsError(storableError(e)));
    }
  };

export const createShippingLabel =
  (shipFrom: Omit<CoreAddress, 'id'>) => async (dispatch: Dispatch, getState: () => any) => {
    const { bundle, weightWithUnit } = getState().GenerateShippingLabelPage;

    dispatch(createShippingLabelRequest());

    try {
      if (!bundle || !bundle.lineItems) {
        throw new Error('Bundle is missing');
      }
      const listingIds = bundle.lineItems
        .filter((lineItem: LineItem) => lineItem.code === LineItemCode.Listing)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((lineItem: LineItem) => lineItem.listingLineItem!.sharetribeListingId);

      const createLabelResponse = await createShippingLabelForBundle({
        bundleId: bundle.id,
        addressFrom: shipFrom,
        listingIds,
        weightWithUnit,
      });
      const labelUrl = createLabelResponse.bundle.fulfillment.labelURL;
      const momentPlusExtendedWindow = moment().add(EXTENDED_SHIPPING_WINDOW, 'd');
      const [, postgresUpdateBundleError] = await handle(
        apolloClient.mutate({
          mutation: UpdateBundleDocument,
          variables: {
            input: {
              id: bundle.id,
              expireAt: moment
                .max([momentPlusExtendedWindow, moment(bundle.expireAt)])
                .toISOString(),
            },
          },
        })
      );
      if (postgresUpdateBundleError) {
        log.error(postgresUpdateBundleError, 'shipping-label-extend-bundle-expire-at-failed', {
          bundle,
          newExpireAt: momentPlusExtendedWindow,
        });
      }
      dispatch(createShippingLabelSuccess(labelUrl));
      return labelUrl;
    } catch (e) {
      dispatch(createShippingLabelError(storableError(e)));
      log.error(e as Error, 'create-shipping-label-failed', { bundle, weightWithUnit, shipFrom });
      return undefined;
    }
  };
