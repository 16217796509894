// This file is based off of ST sdk's browser cookie store implementation
// https://github.com/sharetribe/flex-sdk-js/blob/master/src/browser_cookie_store.js.

import Cookies from 'js-cookie';
import { buildDomainForAuthCookie } from '../helpers';
import { SharetribeAuthTokenData } from './tokenStoreUtils';

const generateKey = (clientId: string, namespace: string) => `${namespace}-${clientId}-token`;

interface createCustomBrowserStoreParams {
  clientId: string;
  secure: boolean;
}

// Custom browser store that allows us to control which domain the Sharetribe auth token cookies
// are stored on, so we can easily share auth across Treet shops.
const createCustomBrowserStore = (params: createCustomBrowserStoreParams) => {
  const { clientId, secure } = params;
  const expiration = 30; // 30 days
  const namespace = 'st';
  const key = generateKey(clientId, namespace);
  const cookieDomain = buildDomainForAuthCookie();

  const getToken = () => {
    const cookie = Cookies.get(key);
    if (cookie) return JSON.parse(cookie);
    return null;
  };

  const setToken = (tokenData: SharetribeAuthTokenData) => {
    // Make sure there are no duplicate tokens
    if (Cookies.get(key)) Cookies.remove(key);
    const secureFlag = secure ? { secure: true } : {};
    Cookies.set(key, JSON.stringify(tokenData), {
      expires: expiration,
      domain: cookieDomain,
      ...secureFlag,
    });
  };

  const removeToken = () => {
    Cookies.remove(key);
  };

  return {
    getToken,
    setToken,
    removeToken,
  };
};

export default createCustomBrowserStore;
