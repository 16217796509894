import { FindItemMethod, ListingItemType, OwnListing } from '../../types/sharetribe/listing';
import { ITEM_AVAILABILITY_AVAILABLE } from '../../util/constants';

// eslint-disable-next-line import/prefer-default-export
export const buildDuplicateListingParams = (listing: OwnListing) => {
  const { isBrandDirect, payoutInfo } = listing?.attributes?.publicData || {};
  const { shipFromAddress } = listing?.attributes?.privateData || {};

  const buildDuplicateListingParamsValue = {
    listing,
    shopName: listing.attributes.description,
    isBrand: isBrandDirect,
    cashPayoutPercentage: payoutInfo.cashPayoutPercentage,
    creditPayoutPercentage: payoutInfo.creditPayoutPercentage,
    availability: ITEM_AVAILABILITY_AVAILABLE,
    listingItemType: ListingItemType.Marketplace,
    findItemMethod: FindItemMethod.RelistAsDuplicate,
    sourceListingId: listing.id.uuid,
    price: listing.attributes.price,
    shipFromAddress,
  };

  return buildDuplicateListingParamsValue;
};
