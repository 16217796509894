import React, { FC } from 'react';
import classNames from 'classnames';
import IconSocialMediaFacebook from './IconSocialMediaFacebook';
import IconSocialMediaInstagram from './IconSocialMediaInstagram';
import IconSocialMediaPinterest from './IconSocialMediaPinterest';
import IconSocialMediaTiktok from './IconSocialMediaTiktok';
import IconSocialMediaX from './IconSocialMediaX';
import IconSocialMediaYoutube from './IconSocialMediaYoutube';
import css from './IconSocialMedia.module.css';
import { CustomIconType, useCustomIcon } from '../../../hooks/useCustomIcon';
import CustomIcon from '../../CustomIcon/CustomIcon';

export enum IconSocialMediaType {
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  Pinterest = 'PINTEREST',
  Tiktok = 'TIKTOK',
  Youtube = 'YOUTUBE',
  X = 'X',
}

interface IconSocialMediaProps {
  type: IconSocialMediaType;
  rootClassName?: string;
  className?: string;
}

const customIconTypes: Partial<Record<IconSocialMediaType, CustomIconType>> = {
  [IconSocialMediaType.Facebook]: CustomIconType.SocialMediaFacebook,
  [IconSocialMediaType.Instagram]: CustomIconType.SocialMediaInstagram,
  [IconSocialMediaType.Pinterest]: CustomIconType.SocialMediaPinterest,
  [IconSocialMediaType.Tiktok]: CustomIconType.SocialMediaTiktok,
  [IconSocialMediaType.Youtube]: CustomIconType.SocialMediaYoutube,
  [IconSocialMediaType.X]: CustomIconType.SocialMediaX,
};

const IconSocialMedia: FC<IconSocialMediaProps> = (props: IconSocialMediaProps) => {
  const { rootClassName, className, type } = props;
  const classes = classNames(rootClassName || css.root, className);

  const customIconType = customIconTypes[type];
  const customIcon = customIconType ? useCustomIcon(customIconType) : undefined;
  if (customIcon) {
    return (
      <CustomIcon
        url={customIcon.icon.url}
        altText={type.toLowerCase()}
        className={classes}
        height="16px"
      />
    );
  }

  switch (type) {
    case IconSocialMediaType.Facebook: {
      return <IconSocialMediaFacebook className={classes} />;
    }
    case IconSocialMediaType.Instagram: {
      return <IconSocialMediaInstagram className={classes} />;
    }
    case IconSocialMediaType.Pinterest: {
      return <IconSocialMediaPinterest className={classes} />;
    }
    case IconSocialMediaType.Tiktok: {
      return <IconSocialMediaTiktok className={classes} />;
    }
    case IconSocialMediaType.X: {
      return <IconSocialMediaX className={classes} />;
    }
    case IconSocialMediaType.Youtube: {
      return <IconSocialMediaYoutube className={classes} />;
    }
    default:
      return null;
  }
};

export default IconSocialMedia;
