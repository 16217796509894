import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { useShopConfig } from '../../hooks/shopConfig';
import { useBrandCountryConfig } from '../../hooks/useCountryConfig';
import { useTax } from '../../hooks/useTax';
import { DiscountValueType, LineItemCode } from '../../types/apollo/generated/types.generated';
import { getDiscountTotal, getListingsTotal, getTaxTotal } from '../../util/lineItems';
import { BundleInfo } from '../../types/models/bundle';
import { defaultTreetStyles } from '../../shopConfig/config';
import Divider from '../Divider/Divider';
import { CheckoutLineItem } from '../../types/models/lineItem';
import TypographyWrapper, { TypographyWeight } from '../TypographyWrapper/TypographyWrapper';

interface ReturnLineItemBreakdownProps {
  bundle?: BundleInfo;
  disputedBundleItemIds?: string[];
  returnAmount?: number;
}

const ReturnLineItemBreakdown = (props: ReturnLineItemBreakdownProps) => {
  const { bundle, disputedBundleItemIds, returnAmount } = props;
  const intl = useIntl();
  const { css: brandCss, returnShippingPrice } = useShopConfig();
  const { currencyConfig } = useBrandCountryConfig();
  const { isTaxEnabled } = useTax();

  const bgcolor = brandCss?.backgroundColor || 'white';

  const disputedListingIds = new Set<string>(
    bundle?.bundleItems
      .filter(({ id }) => disputedBundleItemIds?.includes(id))
      .map(({ listing: { sharetribeListingId } }) => sharetribeListingId)
  );

  const lineItems = bundle?.lineItems ?? [];

  const listingLineItems = lineItems.filter(({ code }) => code === LineItemCode.Listing) ?? [];

  const disputedListingLineItems = listingLineItems.filter(
    ({ listingLineItem }) =>
      listingLineItem?.sharetribeListingId &&
      disputedListingIds.has(listingLineItem.sharetribeListingId)
  );

  const disputedListingLineItemsAndAllOtherLineItems = lineItems.filter(
    (lineItem) =>
      // Include all non-listing line items
      !lineItem?.listingLineItem?.sharetribeListingId ||
      // Include disputed listing line items
      disputedListingIds.has(lineItem.listingLineItem.sharetribeListingId)
  );

  const disputedTaxLineItems = lineItems.filter(
    ({ code, taxLineItem }) =>
      code === LineItemCode.Tax &&
      taxLineItem?.metadata?.sharetribeListingId &&
      disputedListingIds.has(
        (taxLineItem?.metadata as { sharetribeListingId: string }).sharetribeListingId
      )
  );

  const taxLineItemName = disputedTaxLineItems.find(
    (lineItem) => lineItem.code === LineItemCode.Tax
  )?.name;

  const isEntireBundleDisputed = disputedListingLineItems.length === listingLineItems.length;
  const isDiscountPercentageType = lineItems.some(
    (lineItem) => lineItem?.discountLineItem?.valueType === DiscountValueType.Percentage
  );
  const shouldIncoporateDiscountLineItems = isEntireBundleDisputed || isDiscountPercentageType;

  const disputedListingsLineItemsTotal =
    getListingsTotal(disputedListingLineItems as CheckoutLineItem[]) / 100;
  const disputedTaxLineItemsTotal = getTaxTotal(disputedTaxLineItems as CheckoutLineItem[]) / 100;
  const disputedDiscountLineItemsTotal =
    getDiscountTotal(disputedListingLineItemsAndAllOtherLineItems as CheckoutLineItem[]) / 100;
  const disputedRefundableLineItemsTotal =
    disputedListingsLineItemsTotal +
    disputedTaxLineItemsTotal - // TODO: TREET-11168 - Find correct way to apply the fixed amount discount when calculating the refund total for partial disputes and returns.
    (shouldIncoporateDiscountLineItems ? disputedDiscountLineItemsTotal : 0);
  const returnShippingPriceTotal = returnShippingPrice?.price ? returnShippingPrice.price / 100 : 0;
  const refundTotal = returnAmount ? returnAmount / 100 : 0;

  return (
    <Box mt={2} display="flex" flexDirection="column" width="100%" bgcolor={bgcolor}>
      <Divider />
      <Box py={2}>
        {/* This conditional is needed because we only want to render certain UI elements
     when there's more than just listing line items present, and we want to avoid
     displaying these elements when there are only listing line items. */}
        {(disputedDiscountLineItemsTotal > 0 || disputedTaxLineItemsTotal > 0) && (
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Typography variant="body2">Items</Typography>
            <TypographyWrapper variant="body2" weight={TypographyWeight.Bold} isDynamicValue>
              {intl.formatNumber(disputedListingsLineItemsTotal, currencyConfig)}
            </TypographyWrapper>
          </Box>
        )}
        {isTaxEnabled && disputedTaxLineItemsTotal > 0 && (
          <Box display="flex" flexDirection="row" justifyContent="space-between" my={1}>
            <Typography variant="body2" display="inline">
              {taxLineItemName || 'Tax'}
            </Typography>
            <Typography variant="body2" style={{ fontWeight: 'bold', textAlign: 'end' }}>
              {intl.formatNumber(disputedTaxLineItemsTotal, currencyConfig)}
            </Typography>
          </Box>
        )}
        {disputedDiscountLineItemsTotal > 0 && shouldIncoporateDiscountLineItems && (
          <Box display="flex" flexDirection="row" justifyContent="space-between" my={1}>
            <Typography variant="body2" display="inline">
              Discount
            </Typography>
            <Typography variant="body2" style={{ fontWeight: 'bold', textAlign: 'end' }}>
              -{intl.formatNumber(disputedDiscountLineItemsTotal, currencyConfig)}
            </Typography>
          </Box>
        )}
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="body2" display="inline">
            Total
          </Typography>
          <TypographyWrapper
            variant="body2"
            typographyOverrides={{ style: { textAlign: 'end' } }}
            weight={TypographyWeight.Bold}
            isDynamicValue
          >
            {intl.formatNumber(disputedRefundableLineItemsTotal, currencyConfig)}
          </TypographyWrapper>
        </Box>
      </Box>
      <Divider />
      <Box py={2}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="body2" display="inline">
            Return Shipping
          </Typography>
          <TypographyWrapper
            variant="body2"
            typographyOverrides={{ style: { textAlign: 'end' } }}
            weight={TypographyWeight.Bold}
            isDynamicValue
          >
            -{intl.formatNumber(returnShippingPriceTotal, currencyConfig)}
          </TypographyWrapper>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" mt={1}>
          <Typography variant="body2" style={{ fontWeight: 'bold' }}>
            Total Refund
          </Typography>
          <TypographyWrapper
            variant="body2"
            weight={TypographyWeight.Bold}
            typographyOverrides={{ style: { color: defaultTreetStyles.red60 } }}
            isDynamicValue
          >
            {intl.formatNumber(refundTotal, currencyConfig)}
          </TypographyWrapper>
        </Box>
      </Box>
    </Box>
  );
};

export default ReturnLineItemBreakdown;
