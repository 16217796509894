import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';

const onlylittleonceSizes: string[] = [
  '2',
  '2/3',
  '3',
  '4',
  '4/5',
  '5',
  '6',
  '6/7',
  '7',
  '8',
  '8/10',
  '10',
  '12',
  '12/14',
  '14',
  '16',
];

export const onlylittleonceShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({
      sizes: onlylittleonceSizes,
    }),
  ],
};
