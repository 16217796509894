import React from 'react';

interface DynamicValueWrapperProps {
  children?: React.ReactElement | string | (string | React.ReactElement)[];
}

/**
 * This component is used to wrap any value that should be dynamic,
 * so that these can be excluded from translations in WeGlot.
 */
const DynamicValueWrapper: React.FC<DynamicValueWrapperProps> = (props) => {
  const { children } = props;
  return <span id="dynamic-value-wrapper">{children}</span>;
};

export default DynamicValueWrapper;
