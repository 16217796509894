/* eslint-disable import/prefer-default-export */
import { getDefaultShopConfig, getDefaultTradeInShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { getSizeFilterConfig } from '../filters/size';
import { getCollectionFilterConfig } from '../filters/collection';
import { BuilderSections } from '../../util/builder';
import { getGenderFilterConfig } from '../filters/gender';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';

const teacollectionSizes = [
  'NB',
  '0-3M',
  '3-6M',
  '6-12M',
  '12-18M',
  '12-24M',
  '18-24M',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'One Size',
];

export const teacollectionShopConfig = {
  ...getDefaultShopConfig(),
  ...getDefaultTradeInShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getCollectionFilterConfig(),
    getGenderFilterConfig(),
    getSizeFilterConfig({ sizes: teacollectionSizes }),
  ],
  builderConfig: {
    sections: {
      [BuilderSections.LandingPageContent]: '8e8af3d02e144a10a6483e2625e41ca4',
      [BuilderSections.TopbarContent]: 'c0903dc3901b44f69eb6c0170fd17f3c',
    },
  },
};
