import { Box, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import React, { FC } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { Button, FieldTextInput, Form, TypographyWrapper } from '..';
import DialogWithCloseButton from '../DialogWithCloseButton/DialogWithCloseButton';

interface UpdateQuantityModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: any) => void;
  inProgress?: boolean;
  listingTitle: string;
  currentQuantity: number;
}
const OLD_QUANTITY_FORM_FIELD_NAME = 'oldQuantity';
export const NEW_QUANTITY_FORM_FIELD_NAME = 'newQuantity';

const UpdateQuantityModal: FC<UpdateQuantityModalProps> = (props) => {
  const { listingTitle, currentQuantity, open, onClose, onSubmit, inProgress } = props;

  return (
    <DialogWithCloseButton
      allowFullScreen={false}
      scroll="paper"
      open={open}
      onClose={onClose}
      titleText={`Update Quantity for ${listingTitle}`}
    >
      <FinalForm
        onSubmit={onSubmit}
        initialValues={{ [OLD_QUANTITY_FORM_FIELD_NAME]: currentQuantity }}
        render={(formRenderProps) => {
          const { handleSubmit, values } = formRenderProps;

          return (
            <Form id="updateQuantityForm" onSubmit={handleSubmit}>
              <DialogContent dividers>
                <DialogContentText>
                  <TypographyWrapper variant="body1">
                    What would you like to update the quantity to?
                  </TypographyWrapper>
                  <Box mt={2}>
                    <FieldTextInput
                      id={OLD_QUANTITY_FORM_FIELD_NAME}
                      name={OLD_QUANTITY_FORM_FIELD_NAME}
                      label="Old Quantity"
                      disabled
                    />
                  </Box>
                  <Box mt={2}>
                    <FieldTextInput
                      id={NEW_QUANTITY_FORM_FIELD_NAME}
                      name={NEW_QUANTITY_FORM_FIELD_NAME}
                      label="New Quantity"
                      type="number"
                      step="1"
                      min="1"
                      showError
                      autoFocus
                    />
                  </Box>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={inProgress || !values[NEW_QUANTITY_FORM_FIELD_NAME]}
                  inProgress={inProgress}
                  type="submit"
                >
                  Update
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      />
    </DialogWithCloseButton>
  );
};

export default UpdateQuantityModal;
