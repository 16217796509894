import { getSizeFilterConfig } from '../filters/size';
import { getDefaultShopConfig } from './default';
import * as custom from '../filters/marketplace-custom-config';
import { getConditionsFilterConfig } from '../filters/condition';
import { getBrandDirectFilterConfig } from '../filters/brandDirect';

const purebabySizes: string[] = [
  '00000',
  '0000',
  '000',
  '00',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  'XS',
  'XS-S',
  'S',
  'S-M',
  'M',
  'M-L',
  'L',
  'OS',
];

export const purebabyShopConfig = {
  ...getDefaultShopConfig(),
  filters: [
    ...custom.filters,
    getBrandDirectFilterConfig(),
    getConditionsFilterConfig(),
    getSizeFilterConfig({
      sizes: purebabySizes,
    }),
  ],
};
