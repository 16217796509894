import compact from 'lodash/compact';
import { Listing } from '../../types/sharetribe/listing';

// Gets all the listings, will loop through all pages
export const getAllListings = async (sdk: any, params: any) => {
  let listings: Listing[] = [];
  let included: any = [];

  let page = 1;
  while (true) {
    // eslint-disable-next-line no-await-in-loop
    const response = await sdk.ownListings.query({
      ...params,
      page,
    });
    const { totalPages } = response?.data?.meta || {};

    listings = compact(listings.concat(response?.data.data as Listing));
    included = compact(included.concat(response?.data.included));

    if (page >= totalPages) {
      break;
    }
    page += 1;
  }

  const totalResponse = { data: { data: listings, included } };
  return totalResponse;
};
