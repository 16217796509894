import React, { Component } from 'react';
import { bool, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button, FormattedMessage, ModalInMobile, NamedLink, TypographyWrapper } from '..';
import { injectIntl, intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routes/routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { defaultTreetStyles } from '../../shopConfig/config';

import css from './SearchFiltersMobile.module.css';
import { TypographyFormat } from '../TypographyWrapper/TypographyWrapper';

class SearchFiltersMobileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { isFiltersOpenOnMobile: false, initialQueryParams: null };

    this.openFilters = this.openFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.closeFilters = this.closeFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
  }

  // Open filters modal, set the initial parameters to current ones
  openFilters() {
    const { onOpenModal, urlQueryParams } = this.props;
    onOpenModal();
    this.setState({ isFiltersOpenOnMobile: true, initialQueryParams: urlQueryParams });
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    const { history, onCloseModal, treetId } = this.props;

    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(treetId),
        {},
        this.state.initialQueryParams
      )
    );
    onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false, initialQueryParams: null });
  }

  // Close the filter modal
  closeFilters() {
    this.props.onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false });
  }

  // Reset all filter query parameters
  resetAll(e) {
    this.props.resetAll(e);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  render() {
    const {
      rootClassName,
      className,
      children,
      sortByComponent,
      listingsAreLoaded,
      resultsCount,
      searchInProgress,
      showAsModalMaxWidth,
      onManageDisableScrolling,
      selectedFiltersCount,
      intl,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const listItemLink = (
      <NamedLink name="NewListingPage">
        <TypographyWrapper variant="body1" format={TypographyFormat.Underlined}>
          list your own item
        </TypographyWrapper>
      </NamedLink>
    );

    const browseAllItemsLink = (
      <NamedLink name="LandingPage" to={{ search: 'mode=raw-query' }}>
        <TypographyWrapper variant="body1" format={TypographyFormat.Underlined}>
          browse all listings
        </TypographyWrapper>
      </NamedLink>
    );

    const noResults =
      selectedFiltersCount > 0 ? (
        <TypographyWrapper variant="body1">
          <FormattedMessage
            id="SearchFilters.noResultsWithFilters"
            values={{ browseAllItemsLink }}
          />
        </TypographyWrapper>
      ) : (
        <TypographyWrapper variant="body1">
          <FormattedMessage id="SearchFiltersMobile.noResults" values={{ listItemLink }} />
        </TypographyWrapper>
      );
    const loadingResults = <FormattedMessage id="SearchFiltersMobile.loadingResults" />;
    const filtersHeading = intl.formatMessage({ id: 'SearchFiltersMobile.heading' });
    const modalCloseButtonMessage = intl.formatMessage({ id: 'SearchFiltersMobile.cancel' });

    const showListingsLabel = intl.formatMessage(
      { id: 'SearchFiltersMobile.showListings' },
      { count: resultsCount }
    );

    const filtersButtonClasses =
      selectedFiltersCount > 0 ? css.filtersButtonSelected : css.filtersButton;
    const hasResults = listingsAreLoaded && resultsCount > 0;
    const hasNoResults = listingsAreLoaded && resultsCount === 0;

    return (
      <div className={classes}>
        <div className={css.searchResultSummary}>
          {searchInProgress && (
            <TypographyWrapper variant="body1">{loadingResults}</TypographyWrapper>
          )}
          {hasNoResults && <TypographyWrapper variant="body1">{noResults}</TypographyWrapper>}
        </div>
        {hasResults && (
          <div className={css.buttons}>
            <Button rootClassName={filtersButtonClasses} onClick={this.openFilters}>
              <FormattedMessage
                id="SearchFiltersMobile.filtersButtonLabel"
                className={css.mapIconText}
              />
            </Button>
            {sortByComponent}
          </div>
        )}
        <ModalInMobile
          id="SearchFiltersMobile.filters"
          isModalOpenOnMobile={this.state.isFiltersOpenOnMobile}
          onClose={this.cancelFilters}
          showAsModalMaxWidth={showAsModalMaxWidth}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          closeButtonMessage={modalCloseButtonMessage}
        >
          <div className={css.modalHeadingWrapper}>
            <span className={css.modalHeading}>
              <TypographyWrapper
                variant="h2"
                typographyOverrides={{ style: { fontWeight: 'bold' } }}
              >
                {filtersHeading}
              </TypographyWrapper>
            </span>
            <button className={css.resetAllButton} onClick={(e) => this.resetAll(e)} type="button">
              <TypographyWrapper
                variant="body1"
                typographyOverrides={{ style: { color: defaultTreetStyles.gray60 } }}
              >
                <FormattedMessage id="SearchFiltersMobile.clearAll" />
              </TypographyWrapper>
            </button>
          </div>
          {this.state.isFiltersOpenOnMobile ? (
            <div className={css.filtersWrapper}>{children}</div>
          ) : null}

          <div className={css.showListingsContainer}>
            <Button className={css.showListingsButton} onClick={this.closeFilters}>
              {showListingsLabel}
            </Button>
          </div>
        </ModalInMobile>
      </div>
    );
  }
}

SearchFiltersMobileComponent.defaultProps = {
  rootClassName: null,
  className: null,
  sortByComponent: null,
  resultsCount: null,
  searchInProgress: false,
  selectedFiltersCount: 0,
  treetId: null,
};

SearchFiltersMobileComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  sortByComponent: node,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  showAsModalMaxWidth: number.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  resetAll: func.isRequired,
  selectedFiltersCount: number,

  // from injectIntl
  intl: intlShape.isRequired,

  // from state
  treetId: string,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const { treetId } = state.initial;
  return { treetId };
};

const SearchFiltersMobile = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(SearchFiltersMobileComponent);

export default SearchFiltersMobile;
